import { OvlBaseElement } from "../../library/OvlBaseElement"
import { ButtonState } from "../../library/OmcButton/OmcButton"
import { TextBoxState } from "../../library/OmcTextbox/OmcTextbox"
import { CheckBoxAttributes } from "../../library/OmcCheckbox/OmcCheckbox"
import {
  OptionAttributes,
  OptionState,
} from "../../library/OmcOption/OmcOption"
import * as validatorFn from "./tab1Validators"

import { T } from "../../globals"
import { html } from "lit-html"
import { InfoState } from "../../library/OmcInfo/OmcInfo"

export type TabContentState1 = {
  mandatoryInfo: InfoState
  mission: TextBoxState
  activityReport: TextBoxState
  actions: {
    category: string
    lineId: number
    checkBoxAttributes: CheckBoxAttributes
  }[]

  sites: TextBoxState
  people: TextBoxState[]
  mandatoryZero: InfoState
  qtyReceipt: TextBoxState
  numberFormatInfo: InfoState
  lastBudget: TextBoxState
  yearBudget: TextBoxState
  financialReport: TextBoxState
  fullTimeSalaries: TextBoxState
  partTimeSalaries: TextBoxState
  volunteers: TextBoxState
  ifNoPleaseExplainInfo: InfoState
  freeProducts: OptionAttributes
  calculationMethod: TextBoxState
  needsInfo: InfoState
  needs: { code: string; options: OptionAttributes }[]
  otherNeeds: TextBoxState
}

let mission: TextBoxState = {
  id: "tMission",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let activityReport = {
  id: "tActivityReport",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}
let actions = []
let needs: { desc: string; code: string; options: OptionAttributes }[] = []
let people: TextBoxState[] = []
let sites: TextBoxState = {
  id: "tab1Sites",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let qtyReceipt: TextBoxState = {
  id: "tab1qtyReceipt",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let lastBudget: TextBoxState = {
  id: "tab1lastBudget",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let yearBudget: TextBoxState = {
  id: "tab1yearBudget",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let financialReport = {
  id: "tab1FinancialReport",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let fullTimSalaries = {
  id: "tab1FulltimeS",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let partTimeSalaries = {
  id: "tab1ParttimeS",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let volunteers = {
  id: "tab1Volunteers",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let freeProducts: OptionAttributes = {
  options: [
    { id: "tab1Option1", label: "Yes", value: "1" },
    { id: "tab1Option2", label: "No", value: "0" },
  ],
  optionValue: "0",
  optionGroup: "freeProducts",
}

let calculationMethod = {
  id: "tab1CalcMethod",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

let otherNeeds = {
  id: "tab1OtherNeeds",
  label: "",
  valueState: { value: "" },
  visible: true,
  width: "100%",
  pristine: true,
  validationMessage: "",
}

export let tabContent1: TabContentState1 = {
  mandatoryInfo: { messages: [] },
  mission,
  activityReport,
  actions,

  sites,
  people,
  mandatoryZero: { messages: [] },
  qtyReceipt,
  numberFormatInfo: { messages: [] },
  lastBudget,
  yearBudget,
  financialReport,
  fullTimeSalaries: fullTimSalaries,
  partTimeSalaries,
  volunteers,
  ifNoPleaseExplainInfo: { messages: [] },
  freeProducts,
  calculationMethod,
  needsInfo: { messages: [] },
  needs,
  otherNeeds,
}

export class OmcTab1 extends OvlBaseElement {
  props: any
  mdcEl: any

  lastActionsHeader: string

  tab1: TabContentState1

  init() {
    this.tab1 = this.props(this.state)
  }

  getUI() {
    let atleastOneNeeds = validatorFn.atLeastOneNeedSelectedValidator(
      undefined,
      this.state
    )
    let calculationMethod = null

    if (this.tab1.freeProducts.optionValue === "0") {
      calculationMethod = html`
        <omc-textbox
          .validation=${validatorFn.calculationMethodValidator}
          .textBox=${this.tab1.calculationMethod}
        ></omc-textbox>
      `
    }
    let invisible = ""
    if (this.state.sub.tab.activeTab !== "tab1") {
      invisible = "height:0; line-height:0; overflow: hidden;"
    }
    return html`
      <div
        style="${invisible}"
        class="mdc-card ${this.state.sub.tab.activeTab === "tab1"
          ? "animated fadeIn"
          : "animated fadeOut"}"
      >
        <div style="margin-left:1%; margin-right:1%;">
          <omc-info .info=${this.tab1.mandatoryInfo}></omc-info>
          <div class="mdc-typography--headline6">
            ${this.state.app.translations.AppAssociation}
          </div>
          <omc-textboxmultiline
            .textBox=${this.tab1.mission}
          ></omc-textboxmultiline>
          <omc-fileupload
            .validation=${validatorFn.mandatoryValidator}
            .textBox=${this.tab1.activityReport}
          ></omc-fileupload>
          <div class="mdc-typography--headline6">
            ${this.state.app.translations.AppActionPrincipales}
          </div>
          ${this.tab1.actions.map((m) => {
            let groupHeader
            if (this.lastActionsHeader !== m.category) {
              groupHeader = html`<div
                class="actionsgroupheader mdc-typography--subtitle2"
              >
                ${m.category}
              </div>`
              this.lastActionsHeader = m.category
            }
            return html`
              ${groupHeader}
              <div>
                <omc-checkbox .checkBox=${m.checkBoxAttributes}></omc-checkbox>
              </div>
            `
          })}

          <div class="mdc-typography--headline6">${T("AppSites")}</div>
          <omc-textbox
            .textBox=${this.tab1.sites}
            .validation=${validatorFn.sitesValidator}
          >
          </omc-textbox>

          <div class="mdc-typography--headline6">${T("AppPeople")}</div>

          <omc-info .info=${this.tab1.mandatoryZero}></omc-info>

          ${this.tab1.people.map(
            (m) =>
              html`
                <div>
                  <omc-textbox
                    .textBox=${m}
                    .validation=${validatorFn.integerMandatoryValidator}
                  ></omc-textbox>
                </div>
              `
          )}
          <omc-textbox .textBox=${this.tab1.qtyReceipt}> </omc-textbox>
          <div class="mdc-typography--headline6">
            ${this.state.app.translations.AppBudget}
          </div>
          <omc-info .info=${this.tab1.numberFormatInfo}> </omc-info>
          <omc-textbox
            .textBox=${this.tab1.lastBudget}
            .validation=${validatorFn.integerGreater0MandatoryValidator}
          >
          </omc-textbox>
          <omc-textbox
            .textBox=${this.tab1.yearBudget}
            .validation=${validatorFn.yearMandatoryValidator}
          >
          </omc-textbox>
          <omc-fileupload
            .textBox=${this.tab1.financialReport}
            .validation=${validatorFn.mandatoryValidator}
          ></omc-fileupload>

          <div class="mdc-typography--headline6">${T("AppSalaries")}</div>
          <omc-info .info=${this.tab1.mandatoryZero}> </omc-info>

          <omc-textbox
            .textBox=${this.tab1.fullTimeSalaries}
            .validation=${validatorFn.integerMandatoryValidator}
          >
          </omc-textbox>

          <omc-textbox
            .textBox=${this.tab1.partTimeSalaries}
            .validation=${validatorFn.integerMandatoryValidator}
          >
          </omc-textbox>

          <omc-textbox
            .textBox=${this.tab1.volunteers}
            .validation=${validatorFn.integerMandatoryValidator}
          >
          </omc-textbox>

          <div class="mdc-typography--body1">${T("AppFreeProducts")}</div>

          <omc-info .info=${this.tab1.ifNoPleaseExplainInfo}> </omc-info>
          <omc-option
            .additionalChangeAction=${() =>
              this.actions.OmcTextBoxSetValidationMessage({
                textBox: this.tab1.calculationMethod,
                validationMessage: validatorFn.calculationMethodValidator(
                  this.tab1.calculationMethod,
                  this.state
                ),
              })}
            .option=${this.tab1.freeProducts}
          ></omc-option>
          ${calculationMethod}

          <div class="mdc-typography--headline6">${T("AppNeeds")}</div>

          <omc-info .info=${this.tab1.needsInfo}> </omc-info>

          <div class="needsheader">
            <div></div>
            <div>${this.state.app.translations.AppNeedsDescription0}</div>
            <div>${this.state.app.translations.AppNeedsDescription1}</div>
            <div>${this.state.app.translations.AppNeedsDescription2}</div>
            <div>${this.state.app.translations.AppNeedsDescription3}</div>
          </div>
          ${this.tab1.needs.map((m) => {
            return html`<div class="needsoptions">
              <omc-option .option=${m.options}></omc-option>
            </div>`
          })}
          ${atleastOneNeeds}
          <omc-textbox .textBox=${this.tab1.otherNeeds}> </omc-textbox>
        </div>
      </div>
    `
  }
  disconnectedCallback() {
    super.disconnectedCallback()
  }
}
