import { OvlBaseElement } from "../OvlBaseElement"
import { ButtonState } from "../OmcButton/OmcButton"
import { TextBoxState } from "../OmcTextbox/OmcTextbox"
import * as validatorFn from "./OmcLoginValidators"

import { Action } from "../../index"
import { html } from "lit-html"

export let firstRender = true

export type LoginState = {
  ConnectButtonState: ButtonState
  CompanyTextState: TextBoxState
  UserTextState: TextBoxState
  PasswordTextState: TextBoxState
  Title: string
}

export class OmcLogin extends OvlBaseElement {
  props: any

  login: LoginState

  init() {
    firstRender = true
    this.login = this.props(this.state)
  }

  handlesubmit = e => {
    e.preventDefault()
    this.actions.login()
  }

  handleOnChange = e => {}

  getUI() {
    return html`
      <div class="mdc-card" style="width:300px;">
        <form @submit=${this.handlesubmit}>
          <div
            class="mdc-card__primary"
            style="text-align:center;margin-top:-5%;"
          >
            <h1 class="mdc-typography--headline3">${this.login.Title}</h1>
          </div>
          <div
            @change=${this.handleOnChange}
            class="_3Rows"
            style="justify-items:center;"
          >
            <div style="text-align:center;margin-top:-5%;">
              <omc-textbox
                .validation=${validatorFn.companyValidator}
                .textBox=${this.login.CompanyTextState}
              >
              </omc-textbox>
            </div>

            <div style="text-align:center;">
              <omc-textbox
                .validation=${validatorFn.userValidator}
                .textBox=${this.login.UserTextState}
              >
              </omc-textbox>
            </div>

            <div style="text-align:center;">
              <omc-password
                .validation=${validatorFn.passwordValidator}
                .textBox=${this.login.PasswordTextState}
              >
              </omc-password>
            </div>
          </div>

          <div class="mdc-card__actions" style="justify-content:center;">
            <omc-button
              .validation=${state => {
                state.app.indicator.open
                this.login.CompanyTextState.validationMessage
                this.login.UserTextState.validationMessage
                this.login.PasswordTextState.validationMessage
                return (
                  state.app.indicator.open ||
                  !this.login.CompanyTextState.valueState.value ||
                  this.login.CompanyTextState.validationMessage !== "" ||
                  !this.login.UserTextState.valueState.value ||
                  this.login.UserTextState.validationMessage !== "" ||
                  !this.login.PasswordTextState.valueState.value ||
                  this.login.PasswordTextState.validationMessage !== ""
                )
              }}
              .button=${this.login.ConnectButtonState}
            ></omc-button>
          </div>
          <input type="submit" style="display:none" />
        </form>
      </div>
    `
  }
  afterRender() {
    if (firstRender) {
      firstRender = false
      //document.getElementById("tCompany").click()
      //document.getElementById("tCompany").blur()
    }
  }
}
