import { Action, overmind } from "./index"
import {
  OmcCheckBoxChecked,
  CheckBoxAttributes,
} from "./library/OmcCheckbox/OmcCheckbox"
import {
  OmcTextBoxValueChanged,
  OmcTextBoxSetValidationMessage,
  TextBoxState,
} from "./library/OmcTextbox/OmcTextbox"
import { OmcSnackbarChange } from "./library/OmcSnackbar/OmcSnackbar"
import {
  OmcDialogChanged,
  OmcDialogOpen,
  OmcDialogResult,
} from "./library/OmcDialog/OmcDialog"
import { OmcOptionChange, OptionState } from "./library/OmcOption/OmcOption"

import { OmcTabChanged } from "./library/OmcTab/OmcTab"
import {
  AppTabNextPressed,
  AppTabPreviousPressed,
  AppTabPressed,
} from "./components/app"
//import { OmcLoginFormdataInit } from "./library/OmcLogin/OmcLoginValidators"
import {
  IAuthenticateParams,
  translations,
  redirect,
  reload,
  translationData,
  T,
} from "./globals"
import {
  getTab1ValidationMessage,
  getTab2ValidationMessage,
} from "./components/app"
import axios from "axios"
import * as tab1ValidatorFn from "./components/tab1/tab1Validators"

import { pipe, forEach, action } from "overmind"
import { Operator } from "./index"

export {
  OmcCheckBoxChecked,
  OmcTextBoxValueChanged,
  OmcTextBoxSetValidationMessage,
  OmcSnackbarChange,
  OmcDialogChanged,
  OmcDialogOpen,
  OmcTabChanged,
  AppTabNextPressed,
  AppTabPreviousPressed,
  AppTabPressed,
  OmcOptionChange,
  //OmcLoginFormdataInit
}

export const logout: Action = async ({ state, effects, actions }) => {
  actions.OmcDialogOpen({
    ok: "AppOk",
    cancel: "AppCancel",
    text: translations.t.AppQuitMessage,
  })
  switch (await OmcDialogResult()) {
    case "ok":
      state.app.isReady = false
      state.app.user = undefined
      reload()
      break
    case "cancel":
      break
  }
}

export const logoutAndRedirect: Action = async ({
  state,
  effects,
  actions,
}) => {
  state.app.isReady = false
  state.app.user = undefined
  //@ts-ignore
  redirect("https://www.donsolidaires.fr/")
}

export const login: Action = async ({ state, effects, actions }) => {
  let a: IAuthenticateParams = {
    company: state.app.loginState.CompanyTextState.valueState.value,
    email: state.app.loginState.UserTextState.valueState.value,
    password: state.app.loginState.PasswordTextState.valueState.value,
  }
  let res = await effects.authenticate(
    a,
    state.app.indicator,
    state.app.snackBar
  )

  if (res.data && res.data.user) {
    state.app.user = res.data.user
    translationData.user = res.data.user
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + state.app.user.Token
    actions.getSubscription()
  } else {
    actions.OmcDialogOpen({
      cancel: "NoButton",
      ok: "AppOk",
      text: res.message,
    })
  }
}
export const saveSubscription: Action = async ({ actions, state, effects }) => {
  state.app.indicator.open = true
  try {
    state.sub.subscription.DS_OSUB.U_Activity_Report =
      state.sub.tabContent1.activityReport.valueState.value
    state.sub.subscription.DS_OSUB.U_Financial_Report =
      state.sub.tabContent1.financialReport.valueState.value

    // see if we need to upload files first
    //@ts-ignore
    let files: any = document.getElementById("tActivityReport").files
    if (files.length > 0) {
      let res = await effects.uploadFile(
        files[0],
        "A",
        state.sub.subscription.DS_OSUB.DocEntry.toString()
      )
      console.log(res)
      state.sub.subscription.DS_OSUB.U_Activity_Report = res.data.fileName
    }

    //@ts-ignore
    files = document.getElementById("tab1FinancialReport").files
    if (files.length > 0) {
      let res = await effects.uploadFile(
        files[0],
        "F",
        state.sub.subscription.DS_OSUB.DocEntry.toString()
      )
      state.sub.subscription.DS_OSUB.U_Financial_Report = res.data.fileName
    }

    //move all fields into dto state

    state.sub.subscription.DS_OSUB.U_Motive =
      state.sub.tabContent1.mission.valueState.value

    state.sub.subscription.DS_OSUB.U_NombreSites = parseInt(
      state.sub.tabContent1.sites.valueState.value
    )

    state.sub.tabContent1.people.forEach((p, i) => {
      state.sub.subscription.DS_SUB2[i].Qty = parseInt(p.valueState.value)
    })

    state.sub.subscription.DS_OSUB.U_Comment_QtyReceipt =
      state.sub.tabContent1.qtyReceipt.valueState.value

    state.sub.subscription.DS_OSUB.U_Budget = parseInt(
      state.sub.tabContent1.lastBudget.valueState.value
    )

    state.sub.subscription.DS_OSUB.U_Year_Last_Budget = parseInt(
      state.sub.tabContent1.yearBudget.valueState.value
    )

    state.sub.subscription.DS_OSUB.U_Qty_FullTime = parseInt(
      state.sub.tabContent1.fullTimeSalaries.valueState.value
    )

    state.sub.subscription.DS_OSUB.U_Qty_PartTime = parseInt(
      state.sub.tabContent1.partTimeSalaries.valueState.value
    )

    state.sub.subscription.DS_OSUB.U_Qty_Unpaid = parseInt(
      state.sub.tabContent1.volunteers.valueState.value
    )

    state.sub.subscription.DS_OSUB.U_Free_Distribution =
      state.sub.tabContent1.freeProducts.optionValue === "1" ? "Y" : "N"

    state.sub.subscription.DS_OSUB.U_Calculation_Mode =
      state.sub.tabContent1.calculationMethod.valueState.value

    state.sub.tabContent1.needs.forEach((n, i) => {
      state.sub.subscription.DS_SUB3[i].Value = parseInt(n.options.optionValue)
    })

    state.sub.tabContent1.actions.forEach((a, i) => {
      state.sub.subscription.DS_SUB4[i].Checked = a.checkBoxAttributes
        .checkedState.checked
        ? "Y"
        : "N"
    })

    state.sub.subscription.DS_OSUB.U_Other_Needs =
      state.sub.tabContent1.otherNeeds.valueState.value

    let res = await effects.saveSubscription(
      state.sub.subscription,
      state.app.indicator,
      state.app.snackBar
    )
    if (res.status != 200) {
      actions.OmcDialogOpen({
        cancel: "NoButton",
        ok: "AppOk",
        text: res.message,
      })
      return
    } else {
      actions.OmcDialogOpen({
        cancel: "NoButton",
        ok: "AppOk",
        text: translations.t[
          "AppSubscriptionSaved" +
            ("121,125".indexOf(state.app.groupCode) > -1 ? "_NW" : "")
        ],
      })
      switch (await OmcDialogResult()) {
        case "ok":
          actions.logoutAndRedirect()
          break
        case "cancel":
          break
      }
    }
  } catch (e) {
    actions.OmcDialogOpen({
      cancel: "NoButton",
      ok: "AppOk",
      text: e,
    })
  } finally {
    state.app.indicator.open = false
  }
}

export const getSubscription: Action = async ({ actions, state, effects }) => {
  let res = await effects.getSubscription(
    state.app.indicator,
    state.app.snackBar
  )
  if (!res.data) {
    if ((res.status = 400)) {
      actions.OmcDialogOpen({
        cancel: "NoButton",
        ok: "AppOk",
        text: res.message,
      })
    }
    return
  }

  state.sub.subscription = res.data
  translationData.sub = res.data
  state.app.groupCode = state.sub.subscription.OCRD.GroupCode.toString()

  state.sub.tab.tabs[0].name = T("TabInformation")
  state.sub.tab.tabs[1].name = T("TabEnvolvement")
  state.sub.tab.tabs[2].name = T(
    "TabInvoicing" + ("121,125".indexOf(state.app.groupCode) > -1 ? "_NW" : "")
  )

  // fill up subscription form
  // and do some initial validation
  // this should be refactored to maybe a MVMHelper (Model-View-Model - Helper)

  state.sub.tabContent1.activityReport.valueState.value =
    state.sub.subscription.DS_OSUB.U_Activity_Report
  state.sub.tabContent1.activityReport.validationMessage =
    tab1ValidatorFn.mandatoryValidator(
      state.sub.tabContent1.activityReport,
      state
    )

  state.sub.tabContent1.mission.valueState.value = state.sub.subscription
    .DS_OSUB.U_Motive
    ? state.sub.subscription.DS_OSUB.U_Motive
    : ""

  state.sub.tabContent1.actions = []
  state.sub.subscription.DS_SUB4.forEach((a) => {
    let translation = a.Action
    let chk: CheckBoxAttributes = {
      checkedState: { checked: a.Checked === "Y" },
      id: "actionschk_" + a.LineId,
      visible: true,
      label: translation,
    }
    state.sub.tabContent1.actions.push({
      lineId: a.LineId,
      category: a.Categorie,
      checkBoxAttributes: chk,
    })
  })

  state.sub.tabContent1.sites.valueState.value = state.sub.subscription.DS_OSUB
    .U_NombreSites
    ? state.sub.subscription.DS_OSUB.U_NombreSites.toString()
    : "0"
  state.sub.tabContent1.sites.validationMessage =
    tab1ValidatorFn.sitesValidator(state.sub.tabContent1.sites, state)
  state.sub.tabContent1.people = []
  state.sub.subscription.DS_SUB2.forEach((a) => {
    let val: string = null
    if (a.Qty !== 0) {
      val = a.Qty.toString()
    } else {
      val = ""
    }
    let translation = a.U_Desc
    let txt: TextBoxState = {
      valueState: { value: val },
      id: "tab1peopletxt" + a.Code,
      visible: true,
      label: translation,
      width: "100%",
      pristine: true,
      validationMessage: "",
    }

    txt.validationMessage = tab1ValidatorFn.integerMandatoryValidator(
      txt,
      state
    )
    state.sub.tabContent1.people.push(txt)
  })

  state.sub.tabContent1.qtyReceipt.valueState.value = state.sub.subscription
    .DS_OSUB.U_Comment_QtyReceipt
    ? state.sub.subscription.DS_OSUB.U_Comment_QtyReceipt
    : ""

  let val = state.sub.subscription.DS_OSUB.U_Budget
  let sVal = ""
  if (val) {
    sVal = val.toString()
  }
  state.sub.tabContent1.lastBudget.valueState.value = sVal
  state.sub.tabContent1.lastBudget.validationMessage =
    tab1ValidatorFn.integerMandatoryValidator(
      state.sub.tabContent1.lastBudget,
      state
    )

  val = state.sub.subscription.DS_OSUB.U_Year_Last_Budget
  sVal = ""
  if (val) {
    sVal = val.toString()
  }
  state.sub.tabContent1.yearBudget.valueState.value = sVal
  state.sub.tabContent1.yearBudget.validationMessage =
    tab1ValidatorFn.integerGreater0MandatoryValidator(
      state.sub.tabContent1.yearBudget,
      state
    )

  state.sub.tabContent1.financialReport.valueState.value =
    state.sub.subscription.DS_OSUB.U_Financial_Report

  state.sub.tabContent1.financialReport.validationMessage =
    tab1ValidatorFn.mandatoryValidator(
      state.sub.tabContent1.financialReport,
      state
    )

  val = state.sub.subscription.DS_OSUB.U_Qty_FullTime
  sVal = ""
  if (val) {
    sVal = val.toString()
  }
  state.sub.tabContent1.fullTimeSalaries.valueState.value = sVal
  state.sub.tabContent1.fullTimeSalaries.validationMessage =
    tab1ValidatorFn.integerMandatoryValidator(
      state.sub.tabContent1.fullTimeSalaries,
      state
    )

  val = state.sub.subscription.DS_OSUB.U_Qty_PartTime
  sVal = ""
  if (val) {
    sVal = val.toString()
  }
  state.sub.tabContent1.partTimeSalaries.valueState.value = sVal
  state.sub.tabContent1.partTimeSalaries.validationMessage =
    tab1ValidatorFn.integerMandatoryValidator(
      state.sub.tabContent1.partTimeSalaries,
      state
    )

  val = state.sub.subscription.DS_OSUB.U_Qty_Unpaid
  sVal = ""
  if (val) {
    sVal = val.toString()
  }
  state.sub.tabContent1.volunteers.valueState.value = sVal
  state.sub.tabContent1.volunteers.validationMessage =
    tab1ValidatorFn.integerMandatoryValidator(
      state.sub.tabContent1.volunteers,
      state
    )

  state.sub.tabContent1.freeProducts.optionValue =
    state.sub.subscription.DS_OSUB.U_Free_Distribution === "Y" ? "1" : "0"

  state.sub.tabContent1.calculationMethod.valueState.value =
    state.sub.subscription.DS_OSUB.U_Calculation_Mode === null
      ? ""
      : state.sub.subscription.DS_OSUB.U_Calculation_Mode
  state.sub.tabContent1.calculationMethod.validationMessage =
    tab1ValidatorFn.calculationMethodValidator(
      state.sub.tabContent1.calculationMethod,
      state
    )
  state.sub.tabContent1.needs = []
  state.sub.subscription.DS_SUB3.forEach((a) => {
    let optionOption: OptionState[] = []
    optionOption.push({
      id: `${a.Code}0`,
      value: "0",
      label: "",
    })
    optionOption.push({
      id: `${a.Code}1`,
      value: "1",
      label: "",
    })
    optionOption.push({
      id: `${a.Code}2`,
      value: "2",
      label: "",
    })
    optionOption.push({
      id: `${a.Code}3`,
      value: "3",
      label: "",
    })

    state.sub.tabContent1.needs.push({
      code: a.Code,
      options: {
        optionLabel: a.U_Desc,
        optionGroup: a.Code,
        optionValue: a.Value.toString(),
        options: optionOption,
      },
    })
  })

  state.sub.tabContent1.otherNeeds.valueState.value =
    state.sub.subscription.DS_OSUB.U_Other_Needs === null
      ? ""
      : state.sub.subscription.DS_OSUB.U_Other_Needs

  // tab init (needs to be at the end)
  if (getTab1ValidationMessage(state) === "") {
    state.sub.tab.tabs[1].enabled = true
  }
  if (getTab2ValidationMessage(state) === "") {
    state.sub.tab.tabs[2].enabled = true
  }

  state.app.isReady = true
}
