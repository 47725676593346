import { OmcTextbox } from "../OmcTextbox/OmcTextbox"
import { html } from "lit-html"
import axios from "axios"
import { api, downloadFile, startlink } from "../../globals"

export class OmcFileupload extends OmcTextbox {
  openlink = e => {
    let el: any = document.getElementById(this.textBox.id)
    if (el.files[0]) {
      startlink(el.files[0], this.textBox.valueState.value)
    } else {
      // download from server
      downloadFile(this.textBox.valueState.value, this.state)
    }
    e.preventDefault()
  }
  handleremoveattachment = e => {
    let el: any = document.getElementById(this.textBox.id)
    el.value = ""
    this.actions.OmcTextBoxValueChanged({
      textBox: this.textBox,
      value: "",
      validFn: this.validation
    })
    e.preventDefault()
  }

  getUI() {
    let file = this.textBox.valueState.value
    if (file === null) {
      file = ""
    }
    let inputStyle = ""
    let fileDisplayStyle = "visibility:hidden;height:0;"

    let style = ""
    if (this.textBox.width) {
      style = "width:" + this.textBox.width.toString() + ";" + style
    }
    let spacer1 = "margin-bottom:-1.5em"
    if (file) {
      //style = style + "margin-bottom:-3.5em;"
      inputStyle = "visibility:hidden;height:0;"
      fileDisplayStyle = style
    } else {
      //style = style + "margin-bottom: -20%;"

      inputStyle = style
    }

    return html`
      <div class="mdc-text-field" style="${inputStyle}">
        <input
          style="z-index:3;"
          accept="*"
          value="file"
          type="file"
          id="${this.textBox.id}"
          class="mdc-text-field__input"
        />
        <div
          class="mdc-typography--Caption"
          style="margin-left:-100%; width:100%;text-align:left;color:grey;"
        >
          &nbsp;&nbsp;&nbsp;&nbsp;${this.textBox.label}
        </div>

        <div class="mdc-line-ripple"></div>
      </div>
      <div style="${spacer1}" id="fileinputspacer1"></div>
      <div
        class="mdc-text-field mdc-text-field--with-trailing-icon"
        style="${fileDisplayStyle}"
      >
        <a
          @click=${this.openlink}
          target="_blank"
          style="margin-top:1.5em;margin-left:1em;"
          href="${file}"
          id="${"link" + this.textBox.id}"
          >${file.replace("C:\\fakepath\\", "")}</a
        >
        <label
          class="mdc-floating-label mdc-floating-label--float-above"
          for="${"link" + this.textBox.id}"
          >${this.textBox.label}</label
        >
        <i
          @click=${this.handleremoveattachment}
          class="material-icons mdc-text-field__icon"
          tabindex="0"
          role="button"
          >highlight_off</i
        >
        <div class="mdc-line-ripple"></div>
      </div>

      <omc-validationtext .props=${state => this.textBox}> </omc-validationtext>
    `
  }
}
