import { OvlBaseElement } from "../OvlBaseElement"

import { html } from "lit-html"

export type InfoState = {
  messages: string[]
}

export class OmcInfo extends OvlBaseElement {
  props: any

  info: InfoState

  init() {
    if (this.props) {
      this.info = this.props(this.state)
    }
    //console.log(this.info)
  }

  getUI() {
    return html`
      <div style="margin-left:0.2em;margin-right:0.2em;">
        <div class="mdc-card" style="margin-top:0.5em;margin-bottom:0.5em;">
          <div class="_2Col1Row">
            <div class="card-icon material-icons">
              info
            </div>
            <div class="mdc-typography--body2">
              ${this.info.messages.map(
                m =>
                  html`
                    <div>${m}</div>
                  `
              )}
            </div>
          </div>
        </div>
      </div>
    `
  }
  disconnectedCallback() {
    super.disconnectedCallback()
  }
}
