import { T } from "./globals"
const emailRegex = /\S+@\S+\.\S+/
export const minChars = (val: string, minChars: number): string => {
  if (!val || val.length < minChars) {
    return T("ValidMinChars", [minChars.toString()])
  }
  return ""
}
export const maxChars = (val: string, maxChars: number): string => {
  if (val) {
    if (val.length > maxChars) {
      return T("ValidMaxChars", [maxChars.toString()])
    }
  }
  return ""
}

export const exactChars = (val: string, exactChars: number): string => {
  if (val) {
    if (val.length != exactChars) {
      return T("ValidExactChars", [exactChars.toString()])
    }
  }
  return ""
}

export const email = (val: string): string => {
  if (!emailRegex.test(val)) {
    return T("ValidEmail")
  }
  return ""
}

export const mandatory = (val: string): string => {
  if (!val) {
    return T("AppMandatoryHint")
  }
  return ""
}

export const integer = (val: string): string => {
  var n = Math.floor(Number(val))
  if (n !== Infinity && String(n) === val && n >= 0) {
    return ""
  } else {
    return T("ValidInteger")
  }
}
export const integerGreater0 = (val: string): string => {
  var n = Math.floor(Number(val))
  if (n !== Infinity && String(n) === val && n > 0) {
    return ""
  } else {
    return T("ValidIntegerGreater0")
  }
}
