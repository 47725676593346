import { TextBoxState } from "../OmcTextbox/OmcTextbox"
import { LoginState, firstRender } from "./OmcLogin"
import * as validators from "../../validation"
import { Action } from "../../index"

export const companyValidator = (self: TextBoxState, state) => {
  // first render is a hack to make autofill onchange not firing a bit more pleasant
  if (firstRender) return ""
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  if ((msg = validators.minChars(self.valueState.value, 3))) return msg
  return ""
}

export const userValidator = (self: TextBoxState, _) => {
  if (firstRender) return ""
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  if ((msg = validators.email(self.valueState.value))) return msg
  return ""
}

export const passwordValidator = (self: TextBoxState, _) => {
  if (firstRender) return ""
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  if ((msg = validators.minChars(self.valueState.value, 3))) return msg
  return ""
}

export const initValidation = (loginState: LoginState, _) => {
  loginState.UserTextState.validationMessage = userValidator(
    loginState.UserTextState,
    _
  )
  loginState.CompanyTextState.validationMessage = companyValidator(
    loginState.CompanyTextState,
    _
  )
  loginState.PasswordTextState.validationMessage = passwordValidator(
    loginState.PasswordTextState,
    _
  )
}

// export const OmcLoginFormdataInit: Action<LoginState> = (state, value) => {
//   value.UserTextState
//   initValidation(value, state)
// }
