import { OvlBaseElement } from "../../library/OvlBaseElement"

import { downloadFile, T } from "../../globals"
import { html } from "lit-html"
import { unsafeHTML } from "lit-html/directives/unsafe-html"

export type TabContentState3 = {}

export let tabContent3: TabContentState3 = {}

export class OmcTab3 extends OvlBaseElement {
  props: any
  mdcEl: any

  tab3: TabContentState3

  init() {
    this.tab3 = this.props(this.state)
  }

  getUI() {
    let invisible = ""
    if (this.state.sub.tab.activeTab !== "tab3") {
      invisible = "height:0; line-height:0; overflow: hidden;"
    }

    let appInvoiceValue_1
    let appInvoiceValue_1_TranslationCode = "AppInvoiceValue_1"

    let appInvoiceValue_2
    let appInvoiceValue_2_TranslationCode = "AppInvoiceValue_2"

    if (this.state.app.groupCode === "121") {
      appInvoiceValue_1_TranslationCode += "_NW1"
      appInvoiceValue_2_TranslationCode += "_NW1"
    } else if (this.state.app.groupCode === "125") {
      appInvoiceValue_1_TranslationCode += "_NW2"
      appInvoiceValue_2_TranslationCode += "_NW2"
    } else if (this.state.app.groupCode === "120") {
      appInvoiceValue_1_TranslationCode += "_NW3"
      appInvoiceValue_2_TranslationCode += "_NW3"
    } else if (this.state.app.groupCode === "123") {
      appInvoiceValue_1_TranslationCode += "_NW4"
      appInvoiceValue_2_TranslationCode += "_NW4"
    }

    let appInvoiceValue_1_Translation = T(appInvoiceValue_1_TranslationCode)
    if (appInvoiceValue_1_Translation) {
      appInvoiceValue_1 = html`
        <li>${unsafeHTML(appInvoiceValue_1_Translation)}</li>
      `
    }

    let appInvoiceValue_2_Translation = T(appInvoiceValue_2_TranslationCode)
    if (appInvoiceValue_2_Translation) {
      appInvoiceValue_2 = html`
        <li>${unsafeHTML(appInvoiceValue_2_Translation)}</li>
      `
    }

    return html`
      <div
        style="${invisible}"
        class="mdc-card ${this.state.sub.tab.activeTab === "tab3"
          ? "animated fadeIn"
          : "animated fadeOut"}"
      >
        <div style="margin-left:1%; margin-right:1%;">
          <div class="mdc-typography--headline6">
            ${unsafeHTML(
              T(
                "AppInvoiceRegulation" +
                  ("121".indexOf(this.state.app.groupCode) > -1 ? "_NW1" : "") +
                  ("125".indexOf(this.state.app.groupCode) > -1 ? "_NW2" : "")
              )
            )}
          </div>
          <ul style="font-size:1.0em" class="mdc-typography--body2">
            ${appInvoiceValue_1} ${appInvoiceValue_2}
          </ul>

          <div style="font-size:1.0em" class="mdc-typography--body2">
            ${unsafeHTML(
              T(
                "AppInvoiceAcceptRenewal" +
                  ("121".indexOf(this.state.app.groupCode) > -1 ? "_NW1" : "") +
                  ("125".indexOf(this.state.app.groupCode) > -1 ? "_NW2" : "")
              )
            )}
          </div>
          <br />
          <div style="font-size:1.0em" class="mdc-typography--body2">
            ${unsafeHTML(T("AppContact"))}
          </div>
        </div>
      </div>
    `
  }
  handleRibDownload = (e) => {
    if (e.target.nodeName === "A") {
      downloadFile("DS_PortalRib.jpg", this.state)
      e.preventDefault()
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback()
  }
}
