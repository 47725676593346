import { OvlBaseElement } from "../OvlBaseElement"
import { MDCRadio } from "@material/radio"
import { MDCFormField } from "@material/form-field"

import { Action } from "../../index"

import { html } from "lit-html"

export type OptionState = {
  id: string
  label: string
  value: string
}

export type OptionAttributes = {
  options: OptionState[]
  optionValue: string
  optionGroup: string
  optionLabel?: string
}

type OptionChangedParam = {
  optionState: OptionAttributes
  newValue: string
}
export const OmcOptionChange: Action<OptionChangedParam> = (_, value) => {
  //console.log("new value(s) selected:")
  //  console.log(state.)
  value.optionState.optionValue = value.newValue
}

export class OmcOption extends OvlBaseElement {
  props: any
  mdcEl: []
  mdcForm: []
  additionalChangeAction: any
  option: OptionAttributes

  handleChange = (e) => {
    this.actions.OmcOptionChange({
      optionState: this.option,
      newValue: this.option.options[e.target.omcIndex].value,
    })
    if (this.additionalChangeAction) {
      this.additionalChangeAction()
    }
  }

  init() {
    this.mdcEl = []
    this.mdcForm = []
    this.addEventListener("change", this.handleChange)
    if (this.props) {
      this.option = this.props(this.state)
    }
  }
  getUI() {
    let label
    if (this.option.optionLabel) {
      label = html`<div class="optionlabel">${this.option.optionLabel}</div>`
    }
    return html`
      ${label}
      ${this.option.options.map((r, i) => {
        return html`
          <div class="mdc-form-field">
            <div class="mdc-radio">
              <input
                class="mdc-radio__native-control"
                .omcIndex=${i}
                type="radio"
                id="${r.id}"
                name="${this.option.optionGroup}"
                ?checked=${r.value === this.option.optionValue}
              />
              <div class="mdc-radio__background">
                <div class="mdc-radio__outer-circle"></div>
                <div class="mdc-radio__inner-circle"></div>
              </div>
            </div>
            <label for="${r.id}">${r.label}</label>
          </div>
        `
      })}
    `
  }
  afterRender() {
    if (this.mdcEl.length === 0) {
      let els = this.querySelectorAll(".mdc-radio")
      els.forEach((e) => {
        //@ts-ignore
        this.mdcEl.push(new MDCRadio(e))
      })

      els = this.querySelectorAll(".mdc-form-field")
      els.forEach((e) => {
        //@ts-ignore
        this.mdcForm.push(new MDCFormField(e))
      })
    }

    // this.mdcEl.forEach((e, i) => {
    //   //@ts-ignore
    //   e.checked = this.option.optionValue === this.option.options[i].value
    // })
  }
  disconnectedCallback() {
    super.disconnectedCallback()
    //@ts-ignore
    this.mdcEl.forEach((e) => e.destroy())
    //@ts-ignore
    this.mdcForm.forEach((e) => e.destroy())
    this.removeEventListener("change", this.handleChange)
  }
}
