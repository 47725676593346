import { OvlBaseElement } from "../OvlBaseElement"
import { MDCDialog } from "@material/dialog"
import { Action } from "../../index"
import { html } from "lit-html"

type OmcDialogChangedParam = {
  dialogState: DialogState
  result: ResultType
}
type OkType = "AppOk" | "AppYes" | "NoButton"
type CancelType = "AppCancel" | "AppNo" | "NoButton"

type ResultType = undefined | "ok" | "cancel"

export type OpenDialogState = {
  text: string
  ok: OkType
  cancel: CancelType
}

export type DialogState = {
  text: string
  okText: string
  cancelText: string
  visible: boolean
  result: ResultType
}
let dialogResolver: (value?: any) => void
export const OmcDialogResult = () => {
  return new Promise((resolve, reject) => {
    dialogResolver = resolve
  })
}

export const OmcDialogChanged: Action<OmcDialogChangedParam> = (_, value) => {
  value.dialogState.result = value.result
  if (dialogResolver) {
    dialogResolver(value.result)
  }
}

export const OmcDialogOpen: Action<OpenDialogState> = ({ state }, value) => {
  if (value.cancel !== "NoButton") {
    state.app.dialog.cancelText = state.app.translations[value.cancel]
    if (!state.app.dialog.cancelText) {
      state.app.dialog.cancelText = "Cancel"
    }
  } else {
    state.app.dialog.cancelText = ""
  }
  if (value.ok !== "NoButton") {
    state.app.dialog.okText = state.app.translations[value.ok]
    if (!state.app.dialog.okText) {
      state.app.dialog.okText = "Ok"
    }
  } else {
    state.app.dialog.okText = ""
  }
  state.app.dialog.text = value.text
  state.app.dialog.result = undefined
  state.app.dialog.visible = true
}

export class OmcDialog extends OvlBaseElement {
  props: any
  mdcEl: any

  dialog: DialogState

  handleClose = e => {
    let res: ResultType = "ok"
    if (e.detail.action !== "yes") {
      res = "cancel"
    }
    this.actions.OmcDialogChanged({ dialogState: this.dialog, result: res })
  }

  init() {
    this.dialog = this.props(this.state)
  }
  getUI() {
    let okButton = null
    let cancelButton = null
    if (this.dialog.okText) {
      okButton = html`
        <button
          type="button"
          class="mdc-button mdc-dialog__button"
          data-mdc-dialog-action="yes"
        >
          <span class="mdc-button__label">${this.dialog.okText}</span>
        </button>
      `
    }
    if (this.dialog.cancelText) {
      cancelButton = html`
        <button
          type="button"
          class="mdc-button mdc-dialog__button"
          data-mdc-dialog-action="no"
        >
          <span class="mdc-button__label">${this.dialog.cancelText}</span>
        </button>
      `
    }
    return html`
      ${this.dialog.visible
        ? html`
            <div
              class="mdc-dialog"
              role="alertdialog"
              aria-modal="true"
              aria-labelledby="my-dialog-title"
              aria-describedby="my-dialog-content"
            >
              <div class="mdc-dialog__container">
                <div class="mdc-dialog__surface">
                  <!-- Title cannot contain leading whitespace due to mdc-typography-baseline-top() -->
                  <h2 class="mdc-dialog__title" id="my-dialog-title">
                    Don Solidaires e-adhésion
                  </h2>
                  <div class="mdc-dialog__content" id="my-dialog-content">
                    ${this.dialog.text}
                  </div>
                  <footer class="mdc-dialog__actions">
                    ${okButton} ${cancelButton}
                  </footer>
                </div>
              </div>
              <div class="mdc-dialog__scrim"></div>
            </div>
          `
        : null}
    `
  }
  afterRender() {
    if (!this.mdcEl && this.state.app.dialog.visible) {
      let mdcDialog = this.querySelector(".mdc-dialog")
      this.mdcEl = new MDCDialog(mdcDialog)
      mdcDialog.addEventListener("MDCDialog:closed", this.handleClose)
    }
    if (this.state.app.dialog.visible) {
      this.mdcEl.open()
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback()
    this.querySelector(".mdc-dialog").removeEventListener(
      "MDCDialog:closed",
      this.handleClose
    )
    this.mdcEl.destroy()
  }
}
