import { OmcTextbox } from "../OmcTextbox/OmcTextbox"
import { html } from "lit-html"

export class OmcTextboxMultiline extends OmcTextbox {
  getUI() {
    let validationMessage = ""
    if (this.validation) {
      validationMessage = this.validation(this.textBox, this.state)
    }
    let style = "background-color: whitesmoke;margin-top:0.8em;"
    if (this.textBox.width) {
      style = "width:" + this.textBox.width.toString() + ";" + style
    }
    let floatabove = null
    if (this.textBox.valueState.value) {
      floatabove = "mdc-floating-label--float-above"
    }
    return html`
      <div class="mdc-text-field mdc-text-field--textarea" style="${style}">
        <textarea
          style="resize:none;   "
          id="${this.textBox.id}"
          class="mdc-text-field__input"
          rows="2"
          cols="40"
        >
${this.textBox.valueState.value}</textarea
        >
        <label for="${this.textBox.id}" class="mdc-floating-label ${floatabove}"
          >${this.textBox.label}</label
        >
        <div class="mdc-line-ripple"></div>
      </div>
      <omc-validationtext
        .validationMessage=${validationMessage}
        .props=${state => this.textBox.valueState}
      >
      </omc-validationtext>
    `
  }
}
