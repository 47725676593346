import {
  Overmind,
  IAction,
  IConfig,
  IState,
  IDerive,
  IOperator,
  IOnInitialize,
  createOvermind
} from "overmind"
import { state } from "./state"
import * as actions from "./actions"
import * as effects from "./effects"
import onInitialize from "./onInitialize"

import { html, render } from "lit-html"
import { CompOmc } from "./components/app"
import { OmcTab1 } from "./components/tab1/tab1"
import { OmcTab2 } from "./components/tab2/tab2"
import { OmcTab3 } from "./components/tab3/tab3"

import { OmcSnackbar } from "./library/OmcSnackbar/OmcSnackbar"
import { OmcCheckbox } from "./library/OmcCheckbox/OmcCheckbox"
import { OmcTextbox } from "./library/OmcTextbox/OmcTextbox"
import { OmcFileupload } from "./library/OmcFileupload/OmcFileupload"
import { OmcDialog } from "./library/OmcDialog/OmcDialog"
import { OmcIndicator } from "./library/OmcIndicator/OmcIndicator"
import { OmcTab } from "./library/OmcTab/OmcTab"
import { OmcButton } from "./library/OmcButton/OmcButton"
import { OmcLogin } from "./library/OmcLogin/OmcLogin"
import { OmcPassword } from "./library/OmcPassword/OmcPassword"
import { OmcValidationtext } from "./library/OmcValidationtext/OmcValidationtext"
import { OmcInfo } from "./library/OmcInfo/OmcInfo"
import { OmcTextboxMultiline } from "./library/OmcTextboxMultiline/OmcTextboxMultiline"
import { OmcOption } from "./library/OmcOption/OmcOption"

customElements.define("comp-omc", CompOmc)
customElements.define("omc-tab1", OmcTab1)
customElements.define("omc-tab2", OmcTab2)
customElements.define("omc-tab3", OmcTab3)

customElements.define("omc-snackbar", OmcSnackbar)
customElements.define("omc-textbox", OmcTextbox)
customElements.define("omc-fileupload", OmcFileupload)
customElements.define("omc-checkbox", OmcCheckbox)
customElements.define("omc-dialog", OmcDialog)
customElements.define("omc-indicator", OmcIndicator)
customElements.define("omc-tab", OmcTab)
customElements.define("omc-button", OmcButton)
customElements.define("omc-login", OmcLogin)
customElements.define("omc-password", OmcPassword)
customElements.define("omc-validationtext", OmcValidationtext)
customElements.define("omc-info", OmcInfo)
customElements.define("omc-textboxmultiline", OmcTextboxMultiline)
customElements.define("omc-option", OmcOption)

export const config = {
  onInitialize,
  state,
  actions,
  effects
}

export type Config = IConfig<typeof config>

export interface Derive<Parent extends IState, Value>
  extends IDerive<Config, Parent, Value> {}

export interface OnInitialize extends IOnInitialize<Config> {}

export interface Action<Input = void> extends IAction<Config, Input> {}

export interface Operator<Input = void, Output = Input>
  extends IOperator<Config, Input, Output> {}

export const overmind = createOvermind(config, {
  devtools: true,
  logProxies: false
})

// let res = effects.authenticate({
//   email: "info@itflies.ch",
//   company: "A0137",
//   password: "1234"
// })

// console.log(res)

render(
  html`
    <comp-omc id="maincomp"></comp-omc>
  `,
  document.getElementById("app")
)
