import { OvlBaseElement } from "../OvlBaseElement"
import { Action, Derive } from "../../index"
import { html } from "lit-html"
import { MDCTextFieldHelperText } from "@material/textfield/helper-text"
import { TextBoxState } from "../OmcTextbox/OmcTextbox"

export class OmcValidationtext extends OvlBaseElement {
  props: any
  mdcEl: any

  validationState: TextBoxState
  init() {
    if (this.props) {
      this.validationState = this.props(this.state)
    }
  }
  getUI() {
    return html`
      <div class="mdc-text-field-helper-line">
        <div
          class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent"
          style="color:red; margin-top:-0.5em"
          aria-hidden="true"
        >
          ${this.validationState.validationMessage}
        </div>
      </div>
    `
  }
  afterRender() {
    if (!this.mdcEl) {
      this.mdcEl = new MDCTextFieldHelperText(
        this.querySelector(".mdc-text-field-helper-text")
      )
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback()
    this.mdcEl.destroy()
  }
}
