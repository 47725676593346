//import { OnInitialize } from "overmind"
import { OnInitialize } from "./index"
import { api, translationData } from "./globals"
import axios from "axios"
import { translations, T } from "./globals"
import { initValidation } from "./library/OmcLogin/OmcLoginValidators"
import { initApp } from "./globals"

const onInitialize: OnInitialize = async ({
  value: overmind,
  state,
  actions,
  effects,
}) => {
  // @ts-ignore
  state.app.isMobile = window.isMobile.phone
  if (window.location.hostname.toLowerCase().startsWith("demo")) {
    state.app.isDemo = true
    state.app.apiUrl = "https://demo-api-e-adhesion.donsolidaires.fr/api/"
  } else if (
    window.location.hostname
      .toLowerCase()
      .indexOf("e-adhesion.donsolidaires.fr") > -1
  ) {
    state.app.apiUrl = "https://api-e-adhesion.donsolidaires.fr/api/"
  } else {
    state.app.isDemo = true
    state.app.apiUrl = "https://localhost:44362/api/"
  }

  api.url = state.app.apiUrl

  let res = await effects.getTranslations(
    { language: "FR" },
    state.app.indicator,
    state.app.snackBar
  )

  if (!res.data) {
    actions.OmcDialogOpen({
      cancel: "NoButton",
      ok: "AppOk",
      text: res.message,
    })
    return
  }

  translations.t = res.data
  state.app.translations = res.data
  //let t = translations.t
  // set all the translations
  state.app.loginState.CompanyTextState.label = T("LoginCompany")
  state.app.loginState.UserTextState.label = T("LoginUsername")
  state.app.loginState.PasswordTextState.label = T("LoginPassword")
  state.app.loginState.ConnectButtonState.value = T("LoginConnect")
  state.app.loginState.Title = T("LoginTitle")

  state.sub.navButtons.btnNext.value = T("AppNext")
  state.sub.navButtons.btnPrevious.value = T("AppPrevious")

  state.sub.tabContent1.mandatoryInfo.messages = []
  state.sub.tabContent1.mandatoryInfo.messages.push(T("AppMandatoryRemark"))
  state.sub.tabContent1.mandatoryInfo.messages.push(T("AppTabValidRemark"))
  state.sub.tabContent1.mandatoryZero.messages = [T("AppMandatoryZero")]
  state.sub.tabContent1.mission.label = T("AppThanksCompletion")
  state.sub.tabContent1.activityReport.label = T("AppReportActivity")
  state.sub.tabContent1.sites.label = T("AppSites")
  state.sub.tabContent1.qtyReceipt.label = T("AppQtyReceipt")
  state.sub.tabContent1.yearBudget.label = T("AppBudgetYear")
  state.sub.tabContent1.lastBudget.label = T("AppPreviousBudget")
  state.sub.tabContent1.financialReport.label = T("AppReportFinancial")
  state.sub.tabContent1.fullTimeSalaries.label = T("AppFulltimeSalaries")
  state.sub.tabContent1.partTimeSalaries.label = T("AppPartialTimeSalaries")
  state.sub.tabContent1.volunteers.label = T("AppVolunteers")
  state.sub.tabContent1.ifNoPleaseExplainInfo.messages = [
    T("AppFreeProductsCheck"),
  ]
  state.sub.tabContent1.numberFormatInfo.messages = [T("AppNumberFormatInfo")]
  state.sub.tabContent1.freeProducts.options[0].label = T("AppYes")
  state.sub.tabContent1.freeProducts.options[1].label = T("AppNo")

  state.sub.tabContent1.calculationMethod.label = T("AppCalcMethod")

  state.sub.tabContent1.needsInfo.messages = [T("AppNeedsHint")]
  state.sub.tabContent1.otherNeeds.label = T("AppOtherNeeds")

  //tab2

  state.sub.tabContent2.allCheckedInfo.messages = [T("AppConfirmAllAccept")]
  state.sub.tabContent2.useInFrance.label = T("AppConfirmUseInFrance")
  state.sub.tabContent2.redistProducts.label = T("AppConfirmRedistProducts")
  state.sub.tabContent2.marketPrice.label = T("AppConfirmMarketPrice")
  state.sub.tabContent2.productUse.label = T("AppConfirmProductUse")
  state.sub.tabContent2.productCompete.label = T("AppConfirmProductCompete")
  state.sub.tabContent2.productOtherOrganisation.label = T(
    "AppConfirmProductOtherOrganisation"
  )
  state.sub.tabContent2.feedbackUsage.label = T("AppConfirmFeebackUsage")
  state.sub.tabContent2.acceptCondition.label = T("AppConfirmAcceptCondition")

  initValidation(state.app.loginState, state)
  initApp()

  // sample awaitable dialog
  // actions.OmcDialogOpen({ ok: "AppOk", cancel: "AppCancel", text: "Test" })
  // switch (await OmcDialogResult()) {
  //   case "ok":
  //     console.log("Ok pressed")
  //     break
  //   case "cancel":
  //     console.log("Cancel pressed")
  //     break
  // }

  // // ************************* debug init
  // let debug_user = {
  //   CntctCode: 2541,
  //   CardCode: "A0137",
  //   Notes2: "info@itflies.ch",
  //   FirstName: "Peter",
  //   LastName: "Muster",
  //   Token:
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IlNvbmlhIFJPRFJJR1VFUyIsImVtYWlsIjoiaW5mb0BpdGZsaWVzLmNoIiwicm9sZSI6IkUiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoiQTAxMzciLCJuYmYiOjE1NTIyOTI5NzUsImV4cCI6MTU1MjMyODk3NSwiaWF0IjoxNTUyMjkyOTc1fQ.NeEp6xsNc3ARkrCNiZeh9_JvatwKF1UiVs1oAJ0kDQg"
  // }

  // state.app.user = debug_user
  // translationData.user = debug_user
  // axios.defaults.headers.common["Authorization"] =
  //   "Bearer " + state.app.user.Token
  // actions.getSubscription()

  // state.sub.tab.tabs[2].enabled = true
  // state.sub.tab.activeTab = "tab3"
  // state.sub.navButtons.btnNext.value = state.app.translations.AppSave

  // // *************************** debug end
}
export default onInitialize
