import { OmcTextbox } from "../OmcTextbox/OmcTextbox"
import { html } from "lit-html"

export class OmcPassword extends OmcTextbox {
  getUI() {
    let style = ""
    if (this.textBox.width) {
      style = "width:" + this.textBox.width.toString() + ";" + style
    }
    return html`
      <div class="mdc-text-field" style="${style}">
        <input
          value="${this.textBox.valueState.value}"
          type="password"
          id="${this.textBox.id}"
          class="mdc-text-field__input"
        />
        <label class="mdc-floating-label" for="${this.textBox.id}"
          >${this.textBox.label}</label
        >
        <div class="mdc-line-ripple"></div>
      </div>
      <omc-validationtext
        .validationMessage=${this.textBox.validationMessage}
        .props=${state => this.textBox.valueState}
      >
      </omc-validationtext>
    `
  }
}
