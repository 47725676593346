import { EventEmitter } from 'betsy'
import isPlainObject from 'is-plain-obj'
import {
  IFlushCallback,
  IMutation,
  IMutationCallback,
  IS_PROXY,
  ProxyStateTree,
  TTree,
  VALUE,
} from 'proxy-state-tree'
import { Derived } from './derived'
import { Devtools, safeValue, safeValues, DevtoolsMessage } from './Devtools'
import {
  Events,
  EventType,
  Execution,
  NestedPartial,
  Options,
  ResolveActions,
  DefaultMode,
  TestMode,
  SSRMode,
  ResolveState,
} from './internalTypes'
import { proxifyEffects } from './proxyfyEffects'
import {
  IAction,
  IConfiguration,
  IDerive,
  IOperator,
  IState,
  IOnInitialize,
  IContext,
} from './types'
import {
  deepCopy,
  MockedEventEmitter,
  makeStringifySafeMutations,
  mergeState,
  IS_TEST,
  IS_OPERATOR,
  getFunctionName,
  getActionPaths,
  createActionsProxy,
  EXECUTION,
  processState,
} from './utils'
import {
  operatorStarted,
  operatorStopped,
  createContext,
  createNextPath,
  createMutationOperator,
  createOperator,
} from './operator'

export * from './types'

export { createOperator, createMutationOperator }

/** This type can be overwriten by app developers if they want to avoid
 * typing and then they can import `Action`,  `Operation` etc. directly from
 * overmind.
 */
export interface Config {}

export interface Context extends IContext<Config> {}

export interface Action<Value = void, ReturnValue = void>
  extends IAction<Config, Value, ReturnValue> {}

export interface AsyncAction<Value = void, ReturnValue = void>
  extends IAction<Config, Value, Promise<ReturnValue>> {}

export interface Derive<Parent extends IState, Value>
  extends IDerive<Config, Parent, Value> {}

export interface OnInitialize extends IOnInitialize<Config> {}

export const MODE_DEFAULT = Symbol('MODE_DEFAULT')
export const MODE_TEST = Symbol('MODE_TEST')
export const MODE_SSR = Symbol('MODE_SSR')

export const json = (obj: any) =>
  deepCopy(obj && obj[IS_PROXY] ? obj[VALUE] : obj)

export const rehydrate = (state: object, mutations: IMutation[]) => {
  mutations.forEach((mutation) => {
    const pathArray = mutation.path.split('.')
    const key = pathArray.pop()
    const target = pathArray.reduce((aggr, key) => aggr[key], state)

    if (mutation.method === 'set') {
      target[key] = mutation.args[0]
    } else if (mutation.method === 'unset') {
      delete target[key]
    } else {
      target[key][mutation.method](...mutation.args)
    }
  })
}

export interface OvermindSSR<Config extends IConfiguration>
  extends Overmind<Config> {
  hydrate(): IMutation[]
}

export function createOvermindSSR<Config extends IConfiguration>(
  config: Config
): OvermindSSR<Config> {
  const ssr = new Overmind(
    config,
    {
      devtools: false,
    },
    {
      mode: MODE_SSR,
    } as SSRMode
  ) as any

  const mutationTree = ssr.proxyStateTree.getMutationTree()

  ssr.state = mutationTree.state
  ssr.hydrate = () => {
    return mutationTree.flush().mutations
  }
  return ssr
}

export interface OvermindMock<Config extends IConfiguration>
  extends Overmind<Config> {
  onInitialize: () => Promise<IMutation[]>
  mutations: IMutation[]
}

export function createOvermindMock<Config extends IConfiguration>(
  config: Config,
  mockedEffects?: NestedPartial<Config['effects']>
): OvermindMock<Config> {
  const mock = new Overmind(
    Object.assign({}, config, {
      state: deepCopy(config.state),
    }),
    {
      devtools: false,
    },
    {
      mode: MODE_TEST,
      options: {
        effectsCallback: (effect) => {
          const mockedEffect = (effect.name
            ? effect.name.split('.')
            : []
          ).reduce((aggr, key) => (aggr ? aggr[key] : aggr), mockedEffects)

          if (!mockedEffect || (mockedEffect && !mockedEffect[effect.method])) {
            throw new Error(
              `The effect "${effect.name}" with metod ${
                effect.method
              } has not been mocked`
            )
          }
          return mockedEffect[effect.method](...effect.args)
        },
      },
    } as TestMode
  ) as OvermindMock<Config>

  const action = (mock as any).createAction('onInitialize', config.onInitialize)

  mock.onInitialize = () => action(mock)
  mock.mutations = []

  return mock as any
}

export function createOvermind<Config extends IConfiguration>(
  config: Config,
  options?: Options
): Overmind<Config> {
  return new Overmind(config, options, { mode: MODE_DEFAULT })
}

const hotReloadingCache = {}

// We do not use IConfig<Config> directly to type the class in order to avoid
// the 'import(...)' function to be used in exported types.
export class Overmind<ThisConfig extends IConfiguration>
  implements IConfiguration {
  private proxyStateTree: ProxyStateTree<object>
  private actionReferences: Function[] = []
  private nextExecutionId: number = 0
  private mode: DefaultMode | TestMode | SSRMode
  private originalConfiguration
  private derivedReferences: any[] = []
  initialized: Promise<any>
  eventHub: EventEmitter<Events>
  devtools: Devtools
  actions: ResolveActions<ThisConfig['actions']>
  state: ResolveState<ThisConfig['state']>
  effects: ThisConfig['effects'] & {}
  constructor(
    configuration: ThisConfig,
    options: Options = {},
    mode: DefaultMode | TestMode | SSRMode = {
      mode: MODE_DEFAULT,
    } as DefaultMode
  ) {
    const name = options.name || 'OvermindApp'

    if (
      (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') &&
      mode.mode === MODE_DEFAULT &&
      options.hotReloading !== false &&
      !(process && process.title && process.title.includes('node'))
    ) {
      if (hotReloadingCache[name]) {
        return hotReloadingCache[name].reconfigure(configuration)
      } else {
        hotReloadingCache[name] = this
      }
    }

    /*
      Set up an eventHub to trigger information from derived, computed and reactions
    */
    const eventHub =
      mode.mode === MODE_SSR
        ? new MockedEventEmitter()
        : new EventEmitter<Events>()

    /*
      Create the proxy state tree instance with the state and a wrapper to expose
      the eventHub
    */
    const proxyStateTree = this.createProxyStateTree(
      configuration,
      eventHub,
      mode.mode === MODE_SSR ? false : process.env.NODE_ENV === 'development'
    )

    this.originalConfiguration = configuration
    this.state = proxyStateTree.state
    this.effects = configuration.effects || {}
    this.proxyStateTree = proxyStateTree
    this.eventHub = eventHub as EventEmitter<Events>
    this.mode = mode

    if (mode.mode === MODE_SSR) {
      return
    }

    if (
      process.env.NODE_ENV === 'development' &&
      mode.mode === MODE_DEFAULT &&
      typeof window !== 'undefined'
    ) {
      let warning = 'OVERMIND: You are running in DEVELOPMENT mode.'
      if (options.logProxies !== true) {
        const originalConsoleLog = console.log

        console.log = (...args) =>
          originalConsoleLog.apply(
            console,
            args.map((arg) => (arg && arg[IS_PROXY] ? arg[VALUE] : arg))
          )
        warning +=
          '\n\n - To improve debugging experience "console.log" will NOT log proxies from Overmind, but the actual value. Please see docs to turn off this behaviour'
      }

      if (
        options.devtools ||
        (typeof location !== 'undefined' &&
          location.hostname === 'localhost' &&
          options.devtools !== false)
      ) {
        const host =
          options.devtools === true ? 'localhost:3031' : options.devtools
        const name = options.name
          ? options.name
          : typeof document === 'undefined'
          ? 'NoName'
          : document.title || 'NoName'

        this.initializeDevtools(
          host,
          name,
          eventHub,
          proxyStateTree.sourceState,
          configuration.actions
        )
      } else {
        console.log(location.hostname)
        warning +=
          '\n\n - You are not running on localhost. You will have to manually define the devtools option to connect'
      }

      if (!IS_TEST) {
        console.warn(warning)
      }
    }

    if (process.env.NODE_ENV === 'production' && mode.mode === MODE_DEFAULT) {
      eventHub.on(EventType.OPERATOR_ASYNC, () => {
        proxyStateTree.getMutationTree().flush(true)
      })
      eventHub.on(EventType.ACTION_END, (execution) => {
        if (!execution.parentExecution || !execution.parentExecution.isRunning)
          proxyStateTree.getMutationTree().flush()
      })

      let nextTick
      const flushTree = () => {
        proxyStateTree.getMutationTree().flush(true)
      }

      this.proxyStateTree.onMutation(() => {
        nextTick && clearTimeout(nextTick)
        nextTick = setTimeout(flushTree, 0)
      })
    } else if (mode.mode === MODE_DEFAULT) {
      eventHub.on(EventType.OPERATOR_ASYNC, (execution) => {
        const flushData = execution.flush(true)
        if (this.devtools && flushData.mutations.length) {
          this.devtools.send({
            type: 'flush',
            data: {
              ...execution,
              ...flushData,
            },
          })
        }
      })
      eventHub.on(EventType.ACTION_END, (execution) => {
        if (
          !execution.parentExecution ||
          !execution.parentExecution.isRunning
        ) {
          const flushData = execution.flush()

          if (this.devtools && flushData.mutations.length) {
            this.devtools.send({
              type: 'flush',
              data: {
                ...execution,
                ...flushData,
              },
            })
          }
        }
      })
    }

    /*
      Expose the created actions
    */
    this.actions = this.getActions(configuration.actions)

    if (mode.mode === MODE_DEFAULT && configuration.onInitialize) {
      const onInitialize = this.createAction(
        'onInitialize',
        configuration.onInitialize
      ) as any

      this.initialized = Promise.resolve(onInitialize(this))
    } else {
      this.initialized = Promise.resolve(null)
    }
  }
  private createProxyStateTree(
    configuration: IConfiguration,
    eventHub: EventEmitter<any> | MockedEventEmitter,
    devmode: boolean
  ) {
    const proxyStateTree = new ProxyStateTree(
      this.getState(configuration) as any,
      {
        devmode,
        dynamicWrapper: (_, path, func) => func(eventHub, proxyStateTree, path),
        onGetter: devmode
          ? (path, value) => {
              this.eventHub.emitAsync(EventType.GETTER, {
                path,
                value: safeValue(value),
              })
            }
          : undefined,
      }
    )

    return proxyStateTree
  }
  private createExecution(name, action, parentExecution) {
    if (process.env.NODE_ENV === 'production') {
      return ({
        [EXECUTION]: true,
        parentExecution,
        getMutationTree: () => {
          return this.proxyStateTree.getMutationTree()
        },
        emit: this.eventHub.emit.bind(this.eventHub),
      } as any) as Execution
    }

    const mutationTrees: any[] = []
    const execution = {
      [EXECUTION]: true,
      actionId: this.actionReferences.indexOf(action),
      executionId: this.nextExecutionId++,
      actionName: name,
      operatorId: 0,
      isRunning: true,
      parentExecution,
      path: [],
      emit: this.eventHub.emit.bind(this.eventHub),
      send: this.devtools ? this.devtools.send.bind(this.devtools) : () => {},
      trackEffects: this.trackEffects.bind(this, this.effects),
      getNextOperatorId: (() => {
        let currentOperatorId = 0
        return () => ++currentOperatorId
      })(),
      flush: parentExecution
        ? parentExecution.flush
        : (isAsync?: boolean) => {
            return this.proxyStateTree.flush(mutationTrees, isAsync)
          },
      getMutationTree: parentExecution
        ? parentExecution.getMutationTree
        : () => {
            const mutationTree = this.proxyStateTree.getMutationTree()

            mutationTrees.push(mutationTree)

            if (this.mode.mode === MODE_TEST) {
              mutationTree.onMutation((mutation) => {
                this.addExecutionMutation(mutation)
              })
            }

            return mutationTree
          },
      scopeValue: (value, tree) => {
        return this.scopeValue(value, tree)
      },
    }

    return execution
  }
  private createContext(execution, tree) {
    return {
      state: tree.state,
      actions: createActionsProxy(this.actions, (action) => {
        return (value) => action(value, execution.isRunning ? execution : null)
      }),
      execution,
      proxyStateTree: this.proxyStateTree,
      effects: this.trackEffects(this.effects, execution),
    }
  }
  private scopeValue(value: any, tree: TTree) {
    if (!value) {
      return value
    }
    if (value[IS_PROXY]) {
      return this.proxyStateTree.rescope(value, tree)
    } else if (isPlainObject(value)) {
      return Object.assign(
        {},
        ...Object.keys(value).map((key) => ({
          [key]: this.proxyStateTree.rescope(value[key], tree),
        }))
      )
    } else {
      return value
    }
  }
  private addExecutionMutation(mutation: IMutation) {
    ;((this as unknown) as OvermindMock<Config>).mutations.push(mutation)
  }
  private createAction(name, action) {
    this.actionReferences.push(action)
    const actionFunc = (value?, boundExecution?: Execution) => {
      // Developer might unintentionally pass more arguments, so have to ensure
      // that it is an actual execution
      boundExecution =
        boundExecution && boundExecution[EXECUTION] ? boundExecution : undefined

      if (process.env.NODE_ENV === 'production' || action[IS_OPERATOR]) {
        const execution = this.createExecution(name, action, boundExecution)
        this.eventHub.emit(EventType.ACTION_START, {
          ...execution,
          value: safeValue(value),
        })

        if (action[IS_OPERATOR]) {
          return new Promise((resolve, reject) => {
            action(
              null,
              {
                value,
                state: this.proxyStateTree.state,
                actions: this.actions,
                execution,
                effects: this.trackEffects(this.effects, execution),
              },
              (err, finalContext) => {
                execution.isRunning = false
                finalContext &&
                  this.eventHub.emit(EventType.ACTION_END, {
                    ...finalContext.execution,
                    operatorId: finalContext.execution.operatorId - 1,
                  })
                if (err) reject(err)
                else {
                  resolve(
                    this.mode.mode === MODE_TEST
                      ? finalContext.execution
                      : undefined
                  )
                }
              }
            )
          })
        } else {
          const returnValue = action(
            this.createContext(execution, execution.getMutationTree()),
            value
          )

          this.eventHub.emit(EventType.ACTION_END, execution)

          return returnValue
        }
      } else {
        const execution = {
          ...this.createExecution(name, action, boundExecution),
          operatorId: 0,
          type: 'action',
        }
        this.eventHub.emit(EventType.ACTION_START, {
          ...execution,
          value: safeValue(value),
        })
        this.eventHub.emit(EventType.OPERATOR_START, execution)

        const mutationTree = execution.getMutationTree()

        mutationTree.onMutation((mutation) => {
          this.eventHub.emit(EventType.MUTATIONS, {
            ...execution,
            mutations: makeStringifySafeMutations([mutation]),
          })
        })

        const scopedValue = this.scopeValue(value, mutationTree)
        const context = this.createContext(execution, mutationTree)

        try {
          const result = action(context, scopedValue)

          if (result instanceof Promise) {
            this.eventHub.emit(EventType.OPERATOR_ASYNC, execution)
            result.then(() => {
              execution.isRunning = false
              mutationTree.dispose()
              this.eventHub.emit(EventType.OPERATOR_END, {
                ...execution,
                isAsync: true,
                result: undefined,
              })
              this.eventHub.emit(EventType.ACTION_END, execution)
            })
          } else {
            execution.isRunning = false
            mutationTree.dispose()
            this.eventHub.emit(EventType.OPERATOR_END, {
              ...execution,
              isAsync: false,
              result: undefined,
            })
            this.eventHub.emit(EventType.ACTION_END, execution)
          }

          let pendingFlush
          mutationTree.onMutation((mutation) => {
            if (pendingFlush) {
              clearTimeout(pendingFlush)
            }

            if (this.mode.mode === MODE_TEST) {
              this.addExecutionMutation(mutation)
            }

            pendingFlush = setTimeout(() => {
              pendingFlush = null
              const flushData = execution.flush(true)

              if (this.devtools && flushData.mutations.length) {
                this.devtools.send({
                  type: 'flush',
                  data: {
                    ...execution,
                    ...flushData,
                    mutations: makeStringifySafeMutations(flushData.mutations),
                  },
                })
              }
            })
          })

          return result
        } catch (err) {
          this.eventHub.emit(EventType.OPERATOR_END, {
            ...execution,
            isAsync: false,
            result: undefined,
            error: err.message,
          })
          this.eventHub.emit(EventType.ACTION_END, execution)
          throw err
        }
      }
    }

    return actionFunc
  }
  private trackEffects(effects = {}, execution) {
    if (process.env.NODE_ENV === 'production') {
      return effects
    }

    return proxifyEffects(this.effects, (effect) => {
      let result
      try {
        if (this.mode.mode === MODE_TEST) {
          const mode = this.mode as TestMode
          result = mode.options.effectsCallback(effect)
        } else {
          this.eventHub.emit(EventType.EFFECT, {
            ...execution,
            ...effect,
            args: safeValues(effect.args),
            isPending: true,
            error: false,
          })
          result = effect.func.apply(this, effect.args)
        }
      } catch (error) {
        // eslint-disable-next-line standard/no-callback-literal
        this.eventHub.emit(EventType.EFFECT, {
          ...execution,
          ...effect,
          args: safeValues(effect.args),
          isPending: false,
          error: error.message,
        })
        throw error
      }

      if (result instanceof Promise) {
        // eslint-disable-next-line standard/no-callback-literal
        this.eventHub.emit(EventType.EFFECT, {
          ...execution,
          ...effect,
          args: safeValues(effect.args),
          isPending: true,
          error: false,
        })

        return result
          .then((promisedResult) => {
            // eslint-disable-next-line standard/no-callback-literal
            this.eventHub.emit(EventType.EFFECT, {
              ...execution,
              ...effect,
              args: safeValues(effect.args),
              result: safeValue(promisedResult),
              isPending: false,
              error: false,
            })

            return promisedResult
          })
          .catch((error) => {
            this.eventHub.emit(EventType.EFFECT, {
              ...execution,
              ...effect,
              args: safeValues(effect.args),
              isPending: false,
              error: error && error.message,
            })
            throw error
          })
      }

      // eslint-disable-next-line standard/no-callback-literal
      this.eventHub.emit(EventType.EFFECT, {
        ...execution,
        ...effect,
        args: safeValues(effect.args),
        result: safeValue(result),
        isPending: false,
        error: false,
      })

      return result
    })
  }
  private initializeDevtools(host, name, eventHub, initialState, actions) {
    if (process.env.NODE_ENV === 'production') return
    const devtools = new Devtools(name)
    devtools.connect(
      host,
      (message: DevtoolsMessage) => {
        if (message.appName !== name) {
          return
        }

        switch (message.type) {
          case 'refresh':
            location.reload(true)
            break
          case 'executeAction':
            const action = message.data.name
              .split('.')
              .reduce((aggr, key) => aggr[key], this.actions)
            message.data.payload
              ? action(JSON.parse(message.data.payload))
              : action()
            break
          case 'mutation':
            const tree = this.proxyStateTree.getMutationTree()
            const path = message.data.path.slice()
            const value = JSON.parse(`{ "value": ${message.data.value} }`).value
            const key = path.pop()
            const state = path.reduce((aggr, key) => aggr[key], tree.state)

            state[key] = value
            tree.flush(true)
            tree.dispose()
            this.devtools.send({
              type: 'state',
              data: {
                path: message.data.path,
                value,
              },
            })
        }
      }
    )
    for (let type in EventType) {
      eventHub.on(
        EventType[type],
        ((eventType) => (data) => {
          devtools.send({
            type: EventType[type],
            data,
          })

          // Access the derived async, which will trigger calculation and devtools
          if (eventType === EventType.DERIVED_DIRTY) {
            data.derivedPath
              .split('.')
              .reduce((aggr, key) => aggr[key], this.proxyStateTree.state)
          }
        })(EventType[type])
      )
    }
    devtools.send({
      type: 'init',
      data: {
        state: this.proxyStateTree.state,
        actions: getActionPaths(actions),
      },
    })
    this.devtools = devtools
  }
  private getState(configuration: IConfiguration) {
    let state = {}
    if (configuration.state) {
      state = processState(
        configuration.state,
        process.env.NODE_ENV === 'development'
          ? this.derivedReferences
          : undefined
      )
    }

    return state
  }
  private getActions(actions: any = {}, path: string[] = []) {
    return Object.keys(actions).reduce((aggr, name) => {
      if (typeof actions[name] === 'function') {
        const action = this.createAction(
          path.concat(name).join('.'),
          actions[name]
        ) as any

        action.displayName = path.concat(name).join('.')

        return Object.assign(aggr, {
          [name]: action,
        })
      }

      return Object.assign(aggr, {
        [name]: this.getActions(actions[name], path.concat(name)),
      })
    }, {}) as any
  }
  getTrackStateTree() {
    return this.proxyStateTree.getTrackStateTree()
  }
  getMutationTree() {
    return this.proxyStateTree.getMutationTree()
  }
  addMutationListener = (cb: IMutationCallback) => {
    return this.proxyStateTree.onMutation(cb)
  }
  addFlushListener = (cb: IFlushCallback) => {
    return this.proxyStateTree.onFlush(cb)
  }
  reconfigure(configuration: IConfiguration) {
    this.derivedReferences.forEach((derived) => {
      derived.dispose()
    })
    this.derivedReferences.length = 0
    const mergedConfiguration = {
      ...configuration,
      state: mergeState(
        this.originalConfiguration.state,
        this.state,
        configuration.state
      ),
    }
    const proxyStateTree = this.proxyStateTree as any
    this.originalConfiguration.state = configuration.state
    this.proxyStateTree.sourceState = this.getState(mergedConfiguration)
    proxyStateTree.createTrackStateProxifier()
    this.state = this.proxyStateTree.state as any
    this.actions = this.getActions(mergedConfiguration.actions)
    this.effects = mergedConfiguration.effects || {}

    this.proxyStateTree.forceFlush()

    if (this.devtools) {
      this.devtools.send({
        type: 're_init',
        data: {
          state: proxyStateTree.state,
          actions: getActionPaths(configuration.actions),
        },
      })
    }

    return this
  }
}

/*
  OPERATORS
  needs to be in this file for typing override to work
*/
export type Operator<Input = void, Output = Input> = IOperator<
  Config,
  Input,
  Output
>

export function pipe<ThisConfig extends IConfiguration, A, B, Output = B>(
  aOperator: IOperator<ThisConfig, A, B>
): IOperator<ThisConfig, A, Output>

export function pipe<ThisConfig extends IConfiguration, A, B, C, Output = C>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>
): IOperator<ThisConfig, A, Output>

export function pipe<ThisConfig extends IConfiguration, A, B, C, D, Output = D>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>
): IOperator<ThisConfig, A, Output>

export function pipe<
  ThisConfig extends IConfiguration,
  A,
  B,
  C,
  D,
  E,
  Output = E
>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>,
  dOperator: IOperator<ThisConfig, D, E>
): IOperator<ThisConfig, A, Output>

export function pipe<
  ThisConfig extends IConfiguration,
  A,
  B,
  C,
  D,
  E,
  F,
  Output = F
>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>,
  dOperator: IOperator<ThisConfig, D, E>,
  eOperator: IOperator<ThisConfig, E, F>
): IOperator<ThisConfig, A, Output>

export function pipe<
  ThisConfig extends IConfiguration,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  Output = G
>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>,
  dOperator: IOperator<ThisConfig, D, E>,
  eOperator: IOperator<ThisConfig, E, F>,
  fOperator: IOperator<ThisConfig, F, G>
): IOperator<ThisConfig, A, Output>

export function pipe<
  ThisConfig extends IConfiguration,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  Output = H
>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>,
  dOperator: IOperator<ThisConfig, D, E>,
  eOperator: IOperator<ThisConfig, E, F>,
  fOperator: IOperator<ThisConfig, F, G>,
  gOperator: IOperator<ThisConfig, G, H>
): IOperator<ThisConfig, A, Output>

export function pipe<
  ThisConfig extends IConfiguration,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I
>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>,
  dOperator: IOperator<ThisConfig, D, E>,
  eOperator: IOperator<ThisConfig, E, F>,
  fOperator: IOperator<ThisConfig, F, G>,
  gOperator: IOperator<ThisConfig, G, H>,
  hOperator: IOperator<ThisConfig, H, I>
): IOperator<ThisConfig, A, I extends never ? any : I>

export function pipe<
  ThisConfig extends IConfiguration,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J
>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>,
  dOperator: IOperator<ThisConfig, D, E>,
  eOperator: IOperator<ThisConfig, E, F>,
  fOperator: IOperator<ThisConfig, F, G>,
  gOperator: IOperator<ThisConfig, G, H>,
  hOperator: IOperator<ThisConfig, H, I>,
  iOperator: IOperator<ThisConfig, I, J>
): IOperator<ThisConfig, A, J extends never ? any : J>

export function pipe<
  ThisConfig extends IConfiguration,
  A,
  B,
  C,
  D,
  E,
  F,
  G,
  H,
  I,
  J,
  K
>(
  aOperator: IOperator<ThisConfig, A, B>,
  bOperator: IOperator<ThisConfig, B, C>,
  cOperator: IOperator<ThisConfig, C, D>,
  dOperator: IOperator<ThisConfig, D, E>,
  eOperator: IOperator<ThisConfig, E, F>,
  fOperator: IOperator<ThisConfig, F, G>,
  gOperator: IOperator<ThisConfig, G, H>,
  hOperator: IOperator<ThisConfig, H, I>,
  iOperator: IOperator<ThisConfig, I, J>,
  jOperator: IOperator<ThisConfig, J, K>
): IOperator<ThisConfig, A, K extends never ? any : K>

export function pipe(...operators) {
  const instance = (err, context, next, final = next) => {
    if (err) next(err, context)
    else {
      let operatorIndex = 0

      const run = (operatorErr, operatorContext) => {
        const operator = operators[operatorIndex++]

        try {
          ;(operator || next)(operatorErr, operatorContext, run, final)
        } catch (operatorError) {
          ;(operator || next)(operatorError, operatorContext, run, final)
        }
      }

      run(null, context)
    }
  }
  instance[IS_OPERATOR] = true
  return instance
}

/*
  OPERATORS
*/
export function forEach<
  Input extends any[],
  ThisConfig extends IConfiguration = Config
>(
  forEachItemOperator: IOperator<
    ThisConfig,
    Input extends Array<infer U> ? U : never
  >
): IOperator<ThisConfig, Input, Input> {
  const instance = (err, context, next) => {
    if (err) next(err, context)
    else {
      let array = context.value
      let evaluatingCount = array.length
      let lastContext
      let hasErrored = false
      const evaluate = (err) => {
        if (hasErrored) {
          return
        }
        if (err) {
          hasErrored = true
          return next(err)
        }
        evaluatingCount--

        if (!evaluatingCount) {
          operatorStopped(context, context.value)
          next(
            null,
            createContext(
              lastContext,
              context.value,
              lastContext.execution.path &&
                lastContext.execution.path.slice(
                  0,
                  lastContext.execution.path.length - 1
                )
            )
          )
        }
      }
      operatorStarted('forEach', '', context)

      if (array.length) {
        array.forEach((value, index) => {
          lastContext = createContext(
            lastContext || context,
            value,
            context.execution.path &&
              context.execution.path.concat(String(index))
          )
          const nextWithPath = createNextPath(evaluate)
          // @ts-ignore
          forEachItemOperator(null, lastContext, nextWithPath)
        })
      } else {
        operatorStopped(context, context.value)
        next(null, createContext(context, context.value))
      }
    }
  }
  instance[IS_OPERATOR] = true

  return instance as any
}

export function parallel<Input, ThisConfig extends IConfiguration = Config>(
  ...operators: IOperator<ThisConfig, Input>[]
): IOperator<ThisConfig, Input, Input> {
  const instance = (err, context, next) => {
    if (err) next(err, context)
    else {
      let evaluatingCount = operators.length
      let lastContext
      let hasErrored = false
      const evaluate = (err) => {
        if (hasErrored) {
          return
        }
        if (err) {
          hasErrored = true
          return next(err, lastContext)
        }
        evaluatingCount--

        if (!evaluatingCount) {
          operatorStopped(context, context.value)
          next(
            null,
            createContext(
              lastContext,
              context.value,
              lastContext.execution.path &&
                lastContext.execution.path.slice(
                  0,
                  lastContext.execution.path.length - 1
                )
            )
          )
        }
      }
      operatorStarted('parallel', '', context)

      operators.forEach((operator, index) => {
        lastContext = createContext(
          lastContext || context,
          context.value,
          context.execution.path && context.execution.path.concat(String(index))
        )
        const nextWithPath = createNextPath(evaluate)
        // @ts-ignore
        operator(null, lastContext, nextWithPath)
      })
    }
  }
  instance[IS_OPERATOR] = true

  return instance as any
}

export function map<Input, Output, ThisConfig extends IConfiguration = Config>(
  operation: (context: IContext<ThisConfig>, value: Input) => Output
): IOperator<ThisConfig, Input, Output extends Promise<infer U> ? U : Output> {
  return createOperator<ThisConfig>(
    'map',
    getFunctionName(operation),
    (err, context, value, next) => {
      if (err) next(err, value)
      else next(null, operation(context, value))
    }
  )
}

export function noop<
  Input,
  ThisConfig extends IConfiguration = Config
>(): IOperator<ThisConfig, Input> {
  return createOperator<ThisConfig>('noop', '', (err, context, value, next) => {
    if (err) next(err, value)
    else next(null, value)
  })
}

export function filter<Input, ThisConfig extends IConfiguration = Config>(
  operation: (context: IContext<ThisConfig>, value: Input) => boolean
): IOperator<ThisConfig, Input, Input> {
  return createOperator<ThisConfig>(
    'filter',
    getFunctionName(operation),
    (err, context, value, next, final) => {
      if (err) next(err, value)
      else if (operation(context, value)) next(null, value)
      else final(null, value)
    }
  )
}

let hasShownActionDeprecation = false
export function action<Input, ThisConfig extends IConfiguration = Config>(
  operation: (context: IContext<ThisConfig>, value: Input) => void
): IOperator<ThisConfig, Input, Input> {
  if (!hasShownActionDeprecation) {
    console.warn(
      `DEPRECATION - The action operator is deprecated in favor of "mutate". The reason is to avoid confusion between actions and operators. Check out action "${getFunctionName(
        operation
      )}"`
    )
    hasShownActionDeprecation = true
  }

  return createMutationOperator<ThisConfig>(
    'action',
    getFunctionName(operation),
    (err, context, value, next) => {
      if (err) next(err, value)
      else {
        const result = operation(context, value) as any

        if (result instanceof Promise) {
          next(null, result.then(() => value))
        } else {
          next(null, value)
        }
      }
    }
  )
}

export function mutate<Input, ThisConfig extends IConfiguration = Config>(
  operation: (context: IContext<ThisConfig>, value: Input) => void
): IOperator<ThisConfig, Input, Input> {
  return createMutationOperator<ThisConfig>(
    'mutate',
    getFunctionName(operation),
    (err, context, value, next) => {
      if (err) next(err, value)
      else {
        const result = operation(context, value) as any

        if (result instanceof Promise) {
          next(null, result.then(() => value))
        } else {
          next(null, value)
        }
      }
    }
  )
}

export function run<Input, ThisConfig extends IConfiguration = Config>(
  operation: (context: IContext<ThisConfig>, value: Input) => void
): IOperator<ThisConfig, Input, Input> {
  return createOperator<ThisConfig>(
    'run',
    getFunctionName(operation),
    (err, context, value, next) => {
      if (err) next(err, value)
      else {
        const result = operation(context, value) as any

        if (result instanceof Promise) {
          next(null, result.then(() => value))
        } else {
          next(null, value)
        }
      }
    }
  )
}

export function catchError<Input, ThisConfig extends IConfiguration = Config>(
  operation: (context: IContext<ThisConfig>, value: Error) => Input
): IOperator<ThisConfig, Input, Input> {
  return createMutationOperator<ThisConfig>(
    'catchError',
    getFunctionName(operation),
    (err, context, value, next) => {
      if (err) next(null, operation(context, err))
      else
        next(null, value, {
          isSkipped: true,
        })
    }
  )
}

export function tryCatch<
  Input,
  ThisConfig extends IConfiguration = Config
>(paths: {
  try: IOperator<ThisConfig, Input>
  catch: IOperator<ThisConfig, Error>
}): IOperator<ThisConfig, Input, Input> {
  const instance = (err, context, next) => {
    if (err) next(err, context)
    else {
      const evaluateCatch = (err, catchContext) => {
        operatorStopped(context, context.value)
        next(err, createContext(catchContext, context.value))
      }
      const evaluateTry = (err, tryContext) => {
        if (err) {
          const newContext = createContext(
            tryContext,
            err,
            context.execution.path && context.execution.path.concat('catch')
          )
          const nextWithPath = createNextPath(evaluateCatch)

          // @ts-ignore
          paths.catch(null, newContext, nextWithPath)
        } else {
          operatorStopped(context, context.value)
          next(null, createContext(tryContext, context.value))
        }
      }

      operatorStarted('tryCatch', '', context)

      const newContext = createContext(
        context,
        context.value,
        context.execution.path && context.execution.path.concat('try')
      )
      const nextWithPath = createNextPath(evaluateTry)

      // @ts-ignore
      paths.try(null, newContext, nextWithPath)
    }
  }
  instance[IS_OPERATOR] = true

  return instance as any
}

export function fork<
  Input,
  Paths extends { [key: string]: IOperator<ThisConfig, Input, any> },
  ThisConfig extends IConfiguration = Config
>(
  operation: (context: IContext<ThisConfig>, value: Input) => keyof Paths,
  paths: Paths
): IOperator<ThisConfig, Input, Input> {
  return createOperator<ThisConfig>(
    'fork',
    getFunctionName(operation),
    (err, context, value, next) => {
      if (err) next(err, value)
      else {
        const path = operation(context, value)
        next(null, value, {
          path: {
            name: String(path),
            operator: paths[path],
          },
        })
      }
    }
  )
}

export function when<
  Input,
  OutputA,
  OutputB,
  ThisConfig extends IConfiguration = Config
>(
  operation: (context: IContext<ThisConfig>, value: Input) => boolean,
  paths: {
    true: IOperator<ThisConfig, Input, OutputA>
    false: IOperator<ThisConfig, Input, OutputB>
  }
): IOperator<ThisConfig, Input, OutputA | OutputB> {
  return createOperator<ThisConfig>(
    'when',
    getFunctionName(operation),
    (err, context, value, next) => {
      if (err) next(err, value)
      else if (operation(context, value))
        next(null, value, {
          path: {
            name: 'true',
            operator: paths.true,
          },
        })
      else
        next(null, value, {
          path: {
            name: 'false',
            operator: paths.false,
          },
        })
    }
  )
}

export function wait<Input, ThisConfig extends IConfiguration = Config>(
  ms: number
): IOperator<ThisConfig, Input, Input> {
  return createOperator('wait', String(ms), (err, context, value, next) => {
    if (err) next(err, value)
    else setTimeout(() => next(null, value), ms)
  })
}

export function debounce<Input, ThisConfig extends IConfiguration = Config>(
  ms: number
): IOperator<ThisConfig, Input, Input> {
  let timeout
  let previousFinal

  return createOperator(
    'debounce',
    String(ms),
    (err, context, value, next, final) => {
      if (err) next(err, value)
      else {
        if (timeout) {
          clearTimeout(timeout)
          previousFinal(null, value)
        }
        previousFinal = final
        timeout = setTimeout(() => {
          timeout = null
          next(null, value)
        }, ms)
      }
    }
  )
}
