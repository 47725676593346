import { SnackbarState } from "./library/OmcSnackbar/OmcSnackbar"
import { IndicatorState } from "./library/OmcIndicator/OmcIndicator"
import { TabState } from "./library/OmcTab/OmcTab"
import { DialogState } from "./library/OmcDialog/OmcDialog"
import { ButtonState } from "./library/OmcButton/OmcButton"
import { LoginState } from "./library/OmcLogin/OmcLogin"

import { tabContent1 } from "./components/tab1/tab1"
import { tabContent2 } from "./components/tab2/tab2"
import { tabContent3 } from "./components/tab3/tab3"
import { IOCPR, Translation, ISubscriptionData } from "./globals"

let loginState: LoginState = {
  CompanyTextState: {
    id: "tCompany",
    valueState: { value: "" },
    label: "Company",
    visible: true,
    width: "290px",
    pristine: true,
    validationMessage: "",
  },
  UserTextState: {
    id: "tUser",
    valueState: { value: "" },
    label: "Username",
    visible: true,
    width: "290px",
    pristine: true,
    validationMessage: "",
  },
  PasswordTextState: {
    id: "tPassword",
    valueState: { value: "" },
    label: "Password",
    visible: true,
    width: "290px",
    pristine: true,
    validationMessage: "",
  },
  ConnectButtonState: {
    value: "Connect",
    id: "btnLogin",
  },
  Title: "Login",
}

let btnNext: ButtonState = {
  value: "Next",
  id: "NextTab",
}

let btnPrevious: ButtonState = {
  value: "Previous",
  id: "PreviousTab",
}

// let checkBox1: CheckBoxAttributes = {
//   checkedState: { checked: true },
//   id: "myCheckbox1",
//   label: "MyChk1",
//   visible: true
// }

// let checkBox2: CheckBoxAttributes = {
//   checkedState: { checked: false },
//   id: "myCheckbox2",
//   label: "MyChk2",
//   visible: true
// }

// let textBox1: TextBoxState = {
//   id: "mytextbox1",
//   valueState: { value: "initial" },
//   label: "pls some text",
//   visible: true
// }

// let textBox2: TextBoxState = {
//   id: "mytextbox2",
//   valueState: { value: "jris" },
//   label: "pls some text",
//   visible: true
// }

// let fileUpload1: TextBoxState = {
//   id: "fileUpload1",
//   valueState: { value: "" },
//   label: "",
//   visible: true
// }

let indicator: IndicatorState = {
  open: false,
}

let snackBar: SnackbarState = {
  text: "",
}

let dialog: DialogState = {
  cancelText: "Cancel",
  okText: "Ok",
  text: "This is a dialog",
  visible: false,
  result: undefined,
}

let tab: TabState = {
  tabs: [
    { id: "tab1", enabled: true, name: "Tab1" },
    { id: "tab2", enabled: false, name: "Tab2" },
    { id: "tab3", enabled: false, name: "Tab3" },
  ],
  activeTab: "tab1",
}

let user: IOCPR = undefined
let availableLanguages: string[] = []

let translations: Translation = {}
let apiUrl = ""

let subscription: ISubscriptionData = undefined

export const state = {
  app: {
    isDemo: false,
    apiUrl,
    user,
    isMobile: false,
    availableLanguages,
    translations,
    dialog,
    snackBar,
    indicator,
    loginState,
    isReady: false,
    groupCode: "",
  },

  sub: {
    subscription,
    tab,
    tabContent1,
    tabContent2,
    tabContent3,
    navButtons: { btnNext, btnPrevious },
  },
}
