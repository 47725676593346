import { OvlBaseElement } from "../../library/OvlBaseElement"
import { CheckBoxAttributes } from "../../library/OmcCheckbox/OmcCheckbox"
import { downloadFile } from "../../globals"
import { html } from "lit-html"
import { InfoState } from "../../library/OmcInfo/OmcInfo"
import { getTab2ValidationMessage } from "../app"

export type TabContentState2 = {
  useInFrance: CheckBoxAttributes
  redistProducts: CheckBoxAttributes
  marketPrice: CheckBoxAttributes
  productUse: CheckBoxAttributes
  productCompete: CheckBoxAttributes
  productOtherOrganisation: CheckBoxAttributes
  feedbackUsage: CheckBoxAttributes
  acceptCondition: CheckBoxAttributes

  allCheckedInfo: InfoState
}

let useInFrance: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2ChkExlFrance",
  disabled: false,
  label: "",
  visible: true
}

let redistProducts: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2resitProducts",
  disabled: false,
  label: "",
  visible: true
}

let marketPrice: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2marketPrice",
  disabled: false,
  label: "",
  visible: true
}

let productUse: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2productUse",
  disabled: false,
  label: "",
  visible: true
}

let productCompete: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2productCompete",
  disabled: false,
  label: "",
  visible: true
}

let productOtherOrganisation: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2productOtherOrganisation",
  disabled: false,
  label: "",
  visible: true
}

let feedbackUsage: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2feedbackusage",
  disabled: false,
  label: "",
  visible: true
}

let acceptCondition: CheckBoxAttributes = {
  checkedState: { checked: false },
  id: "tab2acceptCondition",
  disabled: false,
  label: "",
  visible: true
}

let allCheckedInfo: InfoState = { messages: [] }

export let tabContent2: TabContentState2 = {
  useInFrance,
  redistProducts,
  marketPrice,
  productUse,
  productCompete,
  productOtherOrganisation,
  feedbackUsage,
  acceptCondition,
  allCheckedInfo
}

export class OmcTab2 extends OvlBaseElement {
  props: any
  mdcEl: any

  tab2: TabContentState2

  init() {
    this.tab2 = this.props(this.state)
  }

  getUI() {
    let allCheckedInfoElement = null
    if (getTab2ValidationMessage(this.state)) {
      allCheckedInfoElement = html`
        <omc-info .info=${this.tab2.allCheckedInfo}> </omc-info>
      `
    }
    let invisible = ""
    if (this.state.sub.tab.activeTab !== "tab2") {
      invisible = "height:0; line-height:0; overflow: hidden;"
    }
    return html`
      <div
        style="${invisible}"
        class="mdc-card ${this.state.sub.tab.activeTab === "tab2"
          ? "animated fadeIn"
          : "animated fadeOut"}"
      >
        <div style="margin-left:1%; margin-right:1%;">
          <div class="mdc-typography--headline6">
            ${this.state.app.translations.AppConfirmTitle}
          </div>
          <div>
            <omc-checkbox .checkBox=${this.tab2.useInFrance}> </omc-checkbox>
          </div>
          <div>
            <omc-checkbox .checkBox=${this.tab2.redistProducts}> </omc-checkbox>
          </div>
          <div>
            <omc-checkbox .checkBox=${this.tab2.marketPrice}> </omc-checkbox>
          </div>
          <div>
            <omc-checkbox .checkBox=${this.tab2.productUse}> </omc-checkbox>
          </div>
          <div>
            <omc-checkbox .checkBox=${this.tab2.productCompete}> </omc-checkbox>
          </div>
          <div>
            <omc-checkbox .checkBox=${this.tab2.productOtherOrganisation}>
            </omc-checkbox>
          </div>
          <div>
            <omc-checkbox .checkBox=${this.tab2.feedbackUsage}> </omc-checkbox>
          </div>
          <hr />
          <div>
            <omc-checkbox
              @click=${this.handleCharteDownload}
              .checkBox=${this.tab2.acceptCondition}
            >
            </omc-checkbox>
          </div>
          ${allCheckedInfoElement}
        </div>
      </div>
    `
  }
  handleCharteDownload = e => {
    if (e.target.nodeName === "A") {
      downloadFile("DS_PortalCharte.pdf", this.state)
      e.preventDefault()
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback()
  }
}
