import { OvlBaseElement } from "../library/OvlBaseElement"
// @ts-ignore
import * as logo from "../../img/logo.png"
import { html } from "lit-html"
import { MDCTopAppBar } from "@material/top-app-bar/index"
import { Action, Config } from "../index"
import { downloadFile, T } from "../globals"
import { atLeastOneNeedSelectedValidator } from "./tab1/tab1Validators"

export const AppTabPressed: Action = ({ state, actions }) => {
  if (state.sub.tab.activeTab === "tab3") {
    state.sub.navButtons.btnNext.value =
      state.app.translations[
        "AppSave" + ("121,125".indexOf(state.app.groupCode) > -1 ? "_NW" : "")
      ]
  } else {
    state.sub.navButtons.btnNext.value = state.app.translations.AppNext
  }
}

export const AppTabNextPressed: Action = ({ state, actions }) => {
  if (
    state.sub.navButtons.btnNext.value ===
    state.app.translations[
      "AppSave" + ("121,125".indexOf(state.app.groupCode) > -1 ? "_NW" : "")
    ]
  ) {
    actions.saveSubscription()
  } else if (state.sub.tab.activeTab === "tab1") {
    state.sub.tab.tabs[1].enabled = true
    state.sub.tab.activeTab = "tab2"
  } else if (state.sub.tab.activeTab === "tab2") {
    state.sub.tab.tabs[2].enabled = true
    state.sub.tab.activeTab = "tab3"
    state.sub.navButtons.btnNext.value =
      state.app.translations[
        "AppSave" + ("121,125".indexOf(state.app.groupCode) > -1 ? "_NW" : "")
      ]
  }
}

export const AppTabPreviousPressed: Action = ({ state, actions }) => {
  state.sub.navButtons.btnNext.value = state.app.translations.AppNext
  if (state.sub.tab.activeTab === "tab3") {
    state.sub.tab.tabs[1].enabled = true
    state.sub.tab.activeTab = "tab2"
  } else if (state.sub.tab.activeTab === "tab2") {
    state.sub.tab.tabs[0].enabled = true
    state.sub.tab.activeTab = "tab1"
  }
}

export class CompOmc extends OvlBaseElement {
  mdcEl: any
  handlePrevious = (e) => {
    if (!e.target.disabled) {
      this.actions.AppTabPreviousPressed()
    }
  }
  handleNext = (e) => {
    if (!e.target.disabled) {
      this.actions.AppTabNextPressed()
    }
  }
  handleHelp = (e) => {
    downloadFile("DS_PortalHelp.pdf", this.state)
    e.preventDefault()
  }
  handleLogout = (e) => {
    this.actions.logout()
    e.preventDefault()
  }
  getUI() {
    let tab = null
    let navButtons = null
    let login = null
    let initialised =
      this.state.app.translations &&
      this.state.app.translations.LoginTitle !== undefined
    let ready = this.state.app.isReady
    let tabContent = null
    if (ready) {
      let subscriptionTitle = T(
        "AppSubscription" +
          ("121,125".indexOf(this.state.app.groupCode) > -1 ? "_NW" : "")
      )
      tab = html`
        <div
          class="mdc-typography--headline5"
          style="margin-top:6em; text-align:center;"
        >
          ${subscriptionTitle}
        </div>
        <omc-tab
          .customAction=${this.actions.AppTabPressed}
          .props=${(state) => state.sub.tab}
        >
        </omc-tab>
        <div style="margin-top:1em;"></div>
      `
      navButtons = html`
        <div style="margin-top:1.5em;margin-bottom:1.5em;">
          <omc-button
            @click=${this.handlePrevious}
            .validation=${(state) => {
              return this.state.sub.tab.activeTab === "tab1"
            }}
            .button=${this.state.sub.navButtons.btnPrevious}
          ></omc-button>
          <omc-button
            @click=${this.handleNext}
            .validation=${(state: Config["state"]) => {
              let res = ""
              if (this.state.app.indicator.open) return "indicator open"
              switch (state.sub.tab.activeTab) {
                case "tab1":
                  res = getTab1ValidationMessage(state)
                  break
                case "tab2":
                  res = getTab2ValidationMessage(state)
                  break
                case "tab3":
                  res = getTab3ValidationMessage(state)
                  break
              }
              return res
            }}
            .button=${this.state.sub.navButtons.btnNext}
          ></omc-button>
        </div>
      `
      tabContent = html`
        <omc-tab1 .props=${(state) => state.sub.tabContent1}></omc-tab1>
        <omc-tab2 .props=${(state) => state.sub.tabContent2}></omc-tab2>
        <omc-tab3 .props=${(state) => state.sub.tabContent3}></omc-tab3>
      `
    } else if (initialised) {
      login = html`
        <div class="_1Col1Row" style="margin-top:10em; justify-items:center">
          <omc-login .props=${(state) => state.app.loginState}></omc-login>
        </div>
      `
    }

    let demooverlay = null
    if (this.state.app.isDemo) {
      if (this.state.app.isMobile) {
        demooverlay = html`
          <div id="demooverlay" style="font-size:8em;top:-10px">NEXT</div>
        `
      } else {
        demooverlay = html` <div id="demooverlay">NEXT</div> `
      }
    }
    let logoMarginLeft = 41
    if (this.state.app.isMobile) {
      logoMarginLeft = 0
    }
    let appBarMenus = null

    let extendedMenu = null

    if (this.state.app.user) {
      if (!this.state.app.isMobile) {
        extendedMenu = html`
          <div style=" margin-right:2vw">
            <p
              class="mdc-typography--caption"
              style="font-size:1em;font-weight:100; "
            >
              ${this.state.app.user.Notes2}
            </p>
          </div>
        `
      }
      appBarMenus = html`
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        >
          ${extendedMenu}
          <div
            class="c-tooltip c-tooltip--bottom"
            aria-label="${this.state.app.translations.AppHelp}"
          >
            <button
              @click=${this.handleHelp}
              class="material-icons mdc-top-app-bar__action-item mdc-ripple-upgraded--unbounded mdc-ripple-upgraded "
              aria-label="${this.state.app.translations.AppHelp}"
              style="--mdc-ripple-fg-size:28px; --mdc-ripple-fg-scale:1.71429; --mdc-ripple-left:10px; --mdc-ripple-top:10px;"
            >
              help_outline
            </button>
          </div>

          <div
            class="c-tooltip c-tooltip--bottom"
            aria-label="${this.state.app.translations.AppLogout}"
          >
            <button
              @click=${this.handleLogout}
              class="material-icons mdc-top-app-bar__action-item mdc-ripple-upgraded--unbounded mdc-ripple-upgraded"
              aria-label="${this.state.app.translations.AppLogout}"
              style="--mdc-ripple-fg-size:28px; --mdc-ripple-fg-scale:1.71429; --mdc-ripple-left:10px; --mdc-ripple-top:10px;"
            >
              power_settings_new
            </button>
          </div>
        </section>
      `
    } else {
      appBarMenus = html`
        <section
          class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end"
        >
          <div
            class="c-tooltip c-tooltip--bottom"
            aria-label="${this.state.app.translations.AppHelp}"
          >
            <button
              @click=${this.handleHelp}
              class="material-icons mdc-top-app-bar__action-item mdc-ripple-upgraded--unbounded mdc-ripple-upgraded "
              aria-label="${this.state.app.translations.AppHelp}"
              style="--mdc-ripple-fg-size:28px; --mdc-ripple-fg-scale:1.71429; --mdc-ripple-left:10px; --mdc-ripple-top:10px;"
            >
              help_outline
            </button>
          </div>
        </section>
      `
    }

    return html`
      ${demooverlay}
      <header
        class="mdc-top-app-bar mdc-top-app-bar--prominent "
        style="top: 0px;"
      >
        <div class="mdc-top-app-bar__row">
          <section class="mdc-top-app-bar__section ">
            <div style="margin-left:${logoMarginLeft}vw">
              <img
                height="120px"
                id="logo"
                alt="Logo"
                href="#/"
                src="${logo}"
              />
            </div>
          </section>
          ${appBarMenus}
        </div>
      </header>

      <div id="spacer" style="margin-top:8em"></div>

      <omc-indicator
        style="position: fixed;z-index:300;width: 100%;right: 0%;top: 8em;"
        .props=${(state) => state.app.indicator}
      ></omc-indicator>

      ${login}
      <div style="margin-left:1%;margin-right:1%;">
        ${tab} ${tabContent} ${navButtons}
      </div>

      <omc-snackbar .props=${(state) => state.app.snackBar}> </omc-snackbar>
      <omc-dialog .props=${(state) => state.app.dialog}></omc-dialog>
    `
  }
  afterRender() {
    if (!this.mdcEl) {
      this.mdcEl = new MDCTopAppBar(this.querySelector(".mdc-top-app-bar"))
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback()
    this.mdcEl.destroy()
    this.mdcEl = undefined
  }
}
export const getTab1ValidationMessage = (state: Config["state"]) => {
  let res =
    state.sub.tabContent1.mission.validationMessage +
    state.sub.tabContent1.otherNeeds.validationMessage +
    state.sub.tabContent1.partTimeSalaries.validationMessage +
    state.sub.tabContent1.fullTimeSalaries.validationMessage +
    state.sub.tabContent1.volunteers.validationMessage +
    state.sub.tabContent1.people.map((m) => m.validationMessage).join("") +
    state.sub.tabContent1.qtyReceipt.validationMessage +
    state.sub.tabContent1.sites.validationMessage +
    state.sub.tabContent1.yearBudget.validationMessage +
    state.sub.tabContent1.activityReport.validationMessage +
    state.sub.tabContent1.financialReport.validationMessage +
    state.sub.tabContent1.lastBudget.validationMessage +
    state.sub.tabContent1.calculationMethod.validationMessage +
    atLeastOneNeedSelectedValidator(undefined, state)

  return res
}

export const getTab2ValidationMessage = (state: Config["state"]) => {
  let res =
    (state.sub.tabContent2.useInFrance.checkedState.checked ? "" : "1") +
    (state.sub.tabContent2.redistProducts.checkedState.checked ? "" : "1") +
    (state.sub.tabContent2.marketPrice.checkedState.checked ? "" : "1") +
    (state.sub.tabContent2.productUse.checkedState.checked ? "" : "1") +
    (state.sub.tabContent2.productCompete.checkedState.checked ? "" : "1") +
    (state.sub.tabContent2.productOtherOrganisation.checkedState.checked
      ? ""
      : "1") +
    (state.sub.tabContent2.feedbackUsage.checkedState.checked ? "" : "1") +
    (state.sub.tabContent2.acceptCondition.checkedState.checked ? "" : "1")
  return res
}

export const getTab3ValidationMessage = (state: Config["state"]) => {
  let res = ""
  return res
}
