import * as validators from "../../validation"
import { Config } from "../../index"
import { T } from "../../globals"

export const sitesValidator = (self, _) => {
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  if ((msg = validators.integerGreater0(self.valueState.value))) return msg
  return ""
}
export const integerMandatoryValidator = (self, _) => {
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  if ((msg = validators.integer(self.valueState.value))) return msg
  return ""
}

export const integerGreater0MandatoryValidator = (self, _) => {
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  if ((msg = validators.integerGreater0(self.valueState.value))) return msg
  return ""
}

export const yearMandatoryValidator = (self, _) => {
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  if ((msg = validators.integer(self.valueState.value))) return msg
  if ((msg = validators.exactChars(self.valueState.value, 4))) return msg
  return ""
}

export const mandatoryValidator = (self, _) => {
  let msg = ""
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  return ""
}

export const calculationMethodValidator = (self, state: Config["state"]) => {
  let msg = ""
  if (state.sub.tabContent1.freeProducts.optionValue === "1") {
    return ""
  }
  if ((msg = validators.mandatory(self.valueState.value))) return msg
  return ""
}

export const atLeastOneNeedSelectedValidator = (
  self,
  state: Config["state"]
) => {
  let msg = ""
  if (
    state.sub.tabContent1.needs.filter((f) => f.options.optionValue !== "0")
      .length < 1
  ) {
    msg = T("AppMandatoryHint")
  }

  return msg
}
