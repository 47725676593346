import { IAuthenticateParams, IGetTranslationsParams } from "./globals"
import { api } from "./globals"
import { IndicatorState } from "./library/OmcIndicator/OmcIndicator"
import { SnackbarState } from "./library/OmcSnackbar/OmcSnackbar"
import { ISubscriptionData } from "./globals"
import axios from "axios"
import { translations } from "./globals"

export const saveSubscription = async (
  data: ISubscriptionData,
  indicator: IndicatorState,
  snackbar: SnackbarState
) => {
  return postRequest(api.url + "subscription", data, indicator, snackbar)
}

export const uploadFile = async (
  file: any,
  fileType: string,
  docEntry: string
) => {
  let res
  try {
    const formData = new FormData()
    formData.append("File", file)
    formData.append("FileType", fileType)
    formData.append("DocEntry", docEntry)

    res = await axios.post(api.url + "attachment/upload", formData)
    return res
  } catch (e) {
    let errMessage = ""

    if (e.response && e.response.data && e.response.data.message) {
      errMessage = e.response.data.message
    } else {
      if (translations.t.ServerError) {
        errMessage = translations.t.ErrorServer
      } else {
        errMessage = "Server Error!"
      }
    }
    throw Error(
      "Error uploading file " + fileType + " errMessage = " + errMessage
    )
  }
}

export const authenticate = (
  data: IAuthenticateParams,
  indicator: IndicatorState,
  snackbar: SnackbarState
) => {
  return postRequest(api.url + "users/authenticate", data, indicator, snackbar)
}

export const getSubscription = (
  indicator: IndicatorState,
  snackbar: SnackbarState
) => {
  return getRequest(api.url + "subscription", indicator, snackbar)
}

export const getTranslations = (
  data: IGetTranslationsParams,
  indicator: IndicatorState,
  snackbar: SnackbarState
) => {
  return postRequest(api.url + "users/translations", data, indicator, snackbar)
}

const postRequest = async (url, data, indicator, snackbar?) => {
  let res
  try {
    indicator.open = true
    res = await axios.post(url, data)
    return res
  } catch (e) {
    let errMessage = ""
    let status = 404
    if (e.response && e.response.data && e.response.data.message) {
      errMessage = e.response.data.message
      status = e.response.status
    } else {
      if (translations.t.ServerError) {
        errMessage = translations.t.ErrorServer
      } else {
        errMessage = "Server Error!"
      }
    }
    if (snackbar) {
      snackbar.text = errMessage
    }
    return { status, message: errMessage }
  } finally {
    indicator.open = false
  }
}

const getRequest = async (url, indicator, snackbar?) => {
  let res
  try {
    indicator.open = true
    res = await axios.get(url)
    return res
  } catch (e) {
    let errMessage = ""
    let status = 404
    if (e.response && e.response.data && e.response.data.message) {
      errMessage = e.response.data.message
      status = e.response.status
    } else {
      if (translations.t.ServerError) {
        errMessage = translations.t.ErrorServer
      } else {
        errMessage = "Server Error!"
      }
    }
    if (snackbar) {
      snackbar.text = errMessage
    }
    return { status, message: errMessage }
  } finally {
    indicator.open = false
  }
}
