import { OvlBaseElement } from "../OvlBaseElement"
import { MDCRipple } from "@material/ripple"
import { Derive } from "../../index"
import { html } from "lit-html"

export type ButtonState = {
  value: string
  id: string
}

export class OmcButton extends OvlBaseElement {
  validation: any
  props: any
  mdcEl: any

  button: ButtonState

  // handleClick = e => {
  //   //console.log("OmcButton clicked")
  // }

  init() {
    //    this.addEventListener("click", this.handleClick)
    if (this.props) {
      this.button = this.props(this.state)
    }
  }
  getUI() {
    let valid = false
    let pointerEvents = ""
    if (this.validation) {
      valid = this.validation(this.state)
    }
    if (valid) {
      pointerEvents = "pointer-events:none;"
    }

    return html`
      <button
        ?disabled=${valid}
        id="${this.button.id}"
        class="mdc-button mdc-button--raised"
      >
        <span style="${pointerEvents}" class="mdc-button__label"
          >${this.button.value}</span
        >
      </button>
    `
  }
  afterRender() {
    let el = this.querySelector(".mdc-button")
    if (!this.mdcEl) {
      this.mdcEl = new MDCRipple(el)
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback()
    this.mdcEl.destroy()
    //  this.removeEventListener("click", this.handleClick)
  }
}
