import axios from "axios"
import { Config } from "./index"
import { overmind } from "./index"
import { logoutAndRedirect } from "./actions"
export let api = { url: "" }
export let translations: Translations = { t: {} }
export let translationData: ITranslationDataRef = {
  sub: undefined,
  user: undefined,
}

interface ITranslationDataRef {
  sub: ISubscriptionData
  user: IOCPR
}

const beforeUnload = (event) => {
  event.preventDefault()
  event.returnValue = translations.t.AppQuitMessage
}

export const redirect = (url: string) => {
  window.removeEventListener("beforeunload", beforeUnload)
  //@ts-ignore
  window.location = url
}

export const reload = () => {
  window.removeEventListener("beforeunload", beforeUnload)
  //@ts-ignore
  window.location =
    window.location.href + "?r=" + Math.floor(Math.random() * 1000000 + 1)
}

export const initApp = () => {
  //@ts-ignore
  window.addEventListener("beforeunload", beforeUnload)
}

export const downloadFile = (fileName: string, state: Config["state"]) => {
  axios
    .post(
      api.url + "attachment/download",
      {
        FileName: fileName,
      },
      { responseType: "arraybuffer" }
    )
    .then((r) => {
      startlink(r.data, fileName)
    })
    .catch((e) => {
      overmind.actions.OmcSnackbarChange({
        text: translations.t.AppFileNotFound.replace("{0}", fileName),
        snackState: state.app.snackBar,
      })
    })
}

export const startlink = (data: any, file: string) => {
  let fileName = file.replace("C:\\fakepath\\", "")
  // not anymore needed cuz edge = chrome?
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   // handle edge differently
  //   window.navigator.msSaveOrOpenBlob(new Blob([data]), fileName)
  // } else {
  const link = document.createElement("a")
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(new Blob([data]))
  link.download = fileName
  // some browser needs the anchor to be in the doc
  document.body.append(link)
  link.click()
  link.remove()
  // in case the Blob uses a lot of memory
  window.addEventListener("focus", (e) => URL.revokeObjectURL(link.href), {
    once: true,
  })
  //}
}

export interface IGetTranslationsParams {
  language: string
}

export const T = (key: string, reps?: string[]): string => {
  let str = translations.t[key]
  if (!str) return ""
  if (str.split("{").length !== str.split("}").length) {
    return str + " invalid{}"
  }
  let hashRes = new Set()
  try {
    str
      .split("{")
      .filter((f) => f.indexOf("}") > -1)
      .map((m) => m.substring(0, m.indexOf("}")))
      .forEach((s) => {
        hashRes.add(s)
      })
    hashRes.forEach((s: string) => {
      let key: string = ""
      if (s.startsWith("T.")) {
        // its a translation itself eg. {T.AppMandatoryHint}
        key = s.substring(2)
        //console.log("found translation key: " + key)
        str = str.split("{" + s + "}").join(T(key, reps))
      } else if (s.startsWith("V.")) {
        // its a data point in state.sub.subscription - tree....eg. {V.OCRD.CardName, or V.DS_OSUB.U_YearFee}
        key = s.substring(2)
        //console.log("found variable key: " + key)
        let data = resolvePath(translationData, key, " not found ")
        str = str.split("{" + s + "}").join(data)
      } else {
        // needs to be a {0}, or {1}, ....
        // so use the replaces array parameter
        str = str.split("{" + s + "}").join(reps[parseInt(s)])
      }
    })
    return str
  } catch (e) {
    return "error in translation string: " + str
  }
}

const resolvePath = (object, path, defaultValue) =>
  path.split(".").reduce((o, p) => (o ? o[p] : defaultValue), object)

type Translations = {
  t: Translation
}
type Variables = {
  v: Variable
}

export type Variable = {
  [key: string]: string
}

export type Translation = {
  [key: string]: string
}

export interface IAuthenticateParams {
  company: string
  email: string
  password: string
}

export interface IAttachmentDownload {
  FileName: string
}
export interface IAttachmentUpload {
  //File: IFormFile
  DocEntry: number
  FileType: string
}
export interface IOCPR {
  CntctCode: number
  CardCode: string
  Notes2: string
  FirstName: string
  LastName: string
  Token: string
}

export interface IOCRD {
  CardName: string
  GroupCode: number
}

export interface IDS_OSUB {
  DocEntry: number
  U_Sub_Statut: string
  U_Budget: number
  U_Year_Last_Budget: number
  U_Activity_Report: string
  U_Financial_Report: string
  U_Qty_FullTime: number
  U_Qty_PartTime: number
  U_Qty_Unpaid: number
  U_NombreSites: number
  U_Year_Fee: number
  U_Comment_QtyReceipt: string
  U_Free_Distribution: string
  U_Calculation_Mode: string
  U_Other_Needs: string
  U_Year: string
  U_Motive: string
}
export interface IDS_SUB2 {
  Code: string
  U_Desc: string
  Qty: number
}
export interface IDS_SUB3 {
  Code: string
  U_Desc: string
  Value: number
}

export interface IDS_SUB4 {
  Code: string
  Categorie: string
  Action: string
  Checked: string
  LineId: number
}

export interface ISubscriptionData {
  OCRD: IOCRD
  DS_OSUB: IDS_OSUB
  DS_SUB2: IDS_SUB2[]
  DS_SUB3: IDS_SUB3[]
  DS_SUB4: IDS_SUB4[]
}
