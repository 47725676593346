import { OvlBaseElement } from "../OvlBaseElement"
import { MDCTextField } from "@material/textfield"
import { Action, Derive } from "../../index"
import { html } from "lit-html"

type OmcTextBoxChangedParam = {
  textBox: TextBoxState
  value: string
  validFn: any
}

type OmcTextBoxSetValidationMessageParam = {
  textBox: TextBoxState
  validationMessage: string
}

export type TextBoxState = {
  valueState: { value: string }
  label: string
  id: string
  visible: boolean
  validationMessage: string
  pristine: boolean
  width?: string
}

export const OmcTextBoxValueChanged: Action<OmcTextBoxChangedParam> = (
  { state },
  value
) => {
  //console.log(value)
  value.textBox.valueState.value = value.value
  value.textBox.pristine = false
  if (value.validFn) {
    value.textBox.validationMessage = value.validFn(value.textBox, state)
  }
}

export const OmcTextBoxSetValidationMessage: Action<
  OmcTextBoxSetValidationMessageParam
> = (state, value) => {
  value.textBox.validationMessage = value.validationMessage
}

export class OmcTextbox extends OvlBaseElement {
  props: any
  validation: any
  mdcEl: any
  // use it eg. for dependend validators
  // in this project eg. the freeDistribution Checkbox and CalculationMethod textbox
  additionalChangeAction: any

  textBox: TextBoxState

  handleChange = e => {
    this.actions.OmcTextBoxValueChanged({
      textBox: this.textBox,
      value: e.target.value,
      validFn: this.validation
    })
    if (this.additionalChangeAction) {
      this.additionalChangeAction()
    }
  }

  init() {
    this.addEventListener("change", this.handleChange)
    //this.mdcEl = []
    if (this.props) {
      this.textBox = this.props(this.state)
    }
  }
  getUI() {
    let style = ""
    if (this.textBox.width) {
      style = "width:" + this.textBox.width.toString() + ";" + style
    }
    let floatabove = null
    if (this.textBox.valueState.value) {
      floatabove = "mdc-floating-label--float-above"
    }
    return html`
      <div <div class="mdc-text-field" style="${style}">
        <input
          value="${this.textBox.valueState.value}"
          type="text"
          id="${this.textBox.id}"
          class="mdc-text-field__input"
        />
        <label class="mdc-floating-label ${floatabove}" for="${this.textBox.id}"
          >${this.textBox.label}</label
        >
        <div class="mdc-line-ripple"></div>
      </div>
      <omc-validationtext .props=${state => this.textBox}> </omc-validationtext>
    `
  }
  afterRender() {
    // if (this.mdcEl.length === 0) {
    //   let els: any = []
    //   els = this.querySelectorAll(".mdc-text-field")
    //   els.forEach(e => {
    //     console.log(e)
    //     this.mdcEl.push(new MDCTextField(e))
    //   })
    // }

    if (!this.mdcEl) {
      this.mdcEl = new MDCTextField(this.querySelector(".mdc-text-field"))
    }
  }
  disconnectedCallback() {
    super.disconnectedCallback()
    this.mdcEl.destroy()
    // this.mdcEl.forEach(m => {
    //   m.destroy()
    // })
    this.removeEventListener("change", this.handleChange)
  }
}
